import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; 
import { useAuth } from '../AuthContext';
import axios from 'axios';
import '../css/login.css';
import VersionDisplay from '../VersionDisplay';
import '../css/tableau.css';
import '../css/bouton.css';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    motDePasse: '',
    showPassword: false,
  });
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const { login } = useAuth();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError('');
  };

  const togglePasswordVisibility = () => {
    setFormData({ ...formData, showPassword: !formData.showPassword });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.email) {
      setError('Veuillez saisir votre email.');
      return;
    }
    if (!formData.motDePasse) {
      setError('Veuillez saisir votre mot de passe.');
      return;
    }

    try {
      const apiBaseUrl = process.env.REACT_APP_API_URL || ''; 
      const response = await axios.post(`${apiBaseUrl}/users/login`, formData);
      login(response.data.token);
      navigate('/view-projects');
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError('Email ou mot de passe invalide.');
      } else {
        setError('Une erreur est survenue. Veuillez réessayer plus tard.');
      }
      console.error('Erreur lors de la connexion:', error);
    }
  };

  return (
    <div className="relative flex justify-center items-center min-h-screen">
      <img 
        src="/fond_vert.png" 
        alt="Bg" 
        className="absolute inset-0 w-full h-full object-cover transform -scale-x-100"
      />
      <div className="relative bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <div className="mb-4 text-center">
          <img src="/logoPowerHouseNudge1.png" alt="PowerHouse Nudge Logo" className="h-12 mx-auto" />
          <h1 className="text-3xl font-bold mb-6">Bienvenue !</h1>
          <p>Connectez-vous à votre compte</p>
        </div>
        
        {error && <div className="bg-red-500 text-white p-2 rounded mb-4">{error}</div>}

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm mb-2" htmlFor="email">Email</label>
            <div className="relative">
              <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400">
                <i className="fas fa-envelope" aria-hidden="true"></i>
              </span>
              <input
                type="email"
                name="email"
                required
                className="placeholder-gray-400 block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md custom-focus"
                placeholder="Entrez votre email"
                value={formData.email}
                onChange={handleChange}
                aria-label="Email"
              />
            </div>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm mb-2" htmlFor="motDePasse">Mot de passe</label>
            <div className="relative">
              <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400">
                <i className="fas fa-lock" aria-hidden="true"></i>
              </span>
              <input
                type={formData.showPassword ? "text" : "password"}
                name="motDePasse"
                required
                className="placeholder-gray-400 block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md custom-focus"
                placeholder="Entrez votre mot de passe"
                value={formData.motDePasse}
                onChange={handleChange}
                aria-label="Mot de passe"
              />
              <span className="absolute inset-y-0 right-0 pr-3 flex items-center">
                <i className={`fas ${formData.showPassword ? "fa-eye" : "fa-eye-slash"} cursor-pointer text-gray-400`} onClick={togglePasswordVisibility} aria-hidden="true"></i>
              </span>
            </div>
          </div>

          <div className="mb-8">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input id="remember_me" type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">Se souvenir de moi</label>
              </div>
              <div>
                <Link to="/forgot-password" className="text-sm text-green-800 hover:text-green-700">Mot de passe oublié ?</Link>
              </div>
            </div>
          </div>
          
          <button
            type="submit"
            className="group relative btn-login ml-16 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-800 hover:bg-green-700 custom-focus"
          >
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <i className="fas fa-sign-in-alt text-white" aria-hidden="true"></i>
            </span>
            Connexion
          </button>
        </form>
      </div>

      <VersionDisplay /> 
    </div>
  );
};

export default Login;
