import React, { useState } from 'react';
import axios from 'axios';
import '../css/bouton.css';
import '../css/tableau.css';
import '../css/formulaire.css';

const UserAccounts = ({ setError, setSuccessMessage, error, successMessage }) => {
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false
  });

  const handlePasswordChange = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };

  const toggleShowPassword = (field) => {
    setShowPassword({ ...showPassword, [field]: !showPassword[field] });
  };

  const changePassword = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      clearErrorAfterTimeout();
      return;
    }
    if (passwordData.newPassword.length < 6) {
      setError("Le nouveau mot de passe doit contenir au moins 6 caractères.");
      clearErrorAfterTimeout();
      return;
    }
    try {
      const token = localStorage.getItem('token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      await axios.put(`${apiBaseUrl}/profils/change-password`, {
        currentPassword: passwordData.currentPassword,
        newPassword: passwordData.newPassword
      }, config);
      setSuccessMessage('Mot de passe changé avec succès.');
      setPasswordData({ currentPassword: '', newPassword: '', confirmPassword: '' });
      clearErrorAfterTimeout();
    } catch (error) {
      console.error('Erreur lors du changement de mot de passe:', error);
      if (error.response) {
        if (error.response.status === 401) {
          setError('Mot de passe actuel incorrect.');
        } else if (error.response.data && error.response.data.errors) {
          setError(error.response.data.errors.map(err => err.msg).join(', '));
        } else {
          setError('Erreur lors du changement de mot de passe. Veuillez réessayer.');
        }
      } else {
        setError('Erreur lors du changement de mot de passe. Veuillez réessayer.');
      }
      clearErrorAfterTimeout();
    }
  };
  

  const clearErrorAfterTimeout = () => {
    setTimeout(() => {
      setError('');
      setSuccessMessage('');
    }, 5000);
  };

  return (
    <div>
      <div className="py-6 border-b border-gray-300 flex items-center justify-between mb-4">
          <h2 className="text-xl font-semibold mb-4">Changer le mot de passe</h2>
          <p className="text-sm text-gray-500">
            <span className="text-red-500">*</span> : Champs obligatoires
          </p>
      </div>
      {error && <div className="bg-red-500 text-white p-2 rounded">{error}</div>}
      {successMessage && <div className="bg-green-500 text-white p-2 rounded">{successMessage}</div>}
      <form onSubmit={changePassword}>
      <div className="mb-4 relative">
        <label className="block text-gray-700 text-sm font-bold mb-2 form-label">Mot de passe actuel <span className="required" title="Ce champ est obligatoire">*</span></label>
        <input
          type={showPassword.currentPassword ? 'text' : 'password'}
          name="currentPassword"
          value={passwordData.currentPassword}
          placeholder={showPassword.currentPassword ? "Le mot de passe est visible" : "Entrez le mot de passe actuel"}
          autoComplete="off"
          onChange={handlePasswordChange}
          required
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight custom-focus"
        />
        <i
          className={`fas ${showPassword.currentPassword ? 'fa-eye-slash' : 'fa-eye'} absolute right-3 top-10 cursor-pointer text-gray-500`}
          onClick={() => toggleShowPassword('currentPassword')}
        ></i>
      </div>

      <div className="mb-4 relative">
        <label className="block text-gray-700 text-sm font-bold mb-2 form-label">Nouveau mot de passe <span className="required" title="Ce champ est obligatoire">*</span></label>
        <input
          type={showPassword.newPassword ? 'text' : 'password'}
          name="newPassword"
          value={passwordData.newPassword}
          placeholder={showPassword.newPassword ? "Le mot de passe est visible" : "Entrez votre nouveau mot de passe"}
          autoComplete="off"
          onChange={handlePasswordChange}
          required
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight custom-focus"
        />
        <i
          className={`fas ${showPassword.newPassword ? 'fa-eye-slash' : 'fa-eye'} absolute right-3 top-10 cursor-pointer text-gray-500`}
          onClick={() => toggleShowPassword('newPassword')}
        ></i>
      </div>

      <div className="mb-6 relative">
        <label className="block text-gray-700 text-sm font-bold mb-2 form-label">Confirmer le nouveau mot de passe <span className="required" title="Ce champ est obligatoire">*</span></label>
        <input
          type={showPassword.confirmPassword ? 'text' : 'password'}
          name="confirmPassword"
          value={passwordData.confirmPassword}
          placeholder={showPassword.confirmPassword ? "Le mot de passe est visible" : "Entrez votre nouveau mot de passe"}
          autoComplete="off"
          onChange={handlePasswordChange}
          required
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight custom-focus"
        />
        <i
          className={`fas ${showPassword.confirmPassword ? 'fa-eye-slash' : 'fa-eye'} absolute right-3 top-10 cursor-pointer text-gray-500`}
          onClick={() => toggleShowPassword('confirmPassword')}
        ></i>
      </div>
        <button
          type="submit"
          className="bouton_vert"
        >
        Mettre à jour
        </button>
      </form>
    </div>
  );
};

export default UserAccounts;
