import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import Login from './components/Login';
import Register from './components/Register';
import CreateProject from './components/CreateProject';
import ViewProjects from './components/ViewProjects';
import UserProfile from './components/UserProfile';
import PrivateRoute from './components/PrivateRoute';
import ProjectDetails from './components/ProjectDetails';
import AddProjectDetail from './components/AddProjectDetail';
import Dashboard from './components/Dashboard';
import UserAccounts from './components/UserAccounts';
import AdminPanel from './components/AdminPanel';
import ForgotPassword from './components/ForgotPassword';
import { AuthProvider, useAuth } from './AuthContext';
import { ProjectProvider, useProjects } from './ProjectContext';
import { CampaignProvider } from './CampaignContext';
import ProjectConfig from './components/ProjectConfig';

const MainContent = () => {
  const { user } = useAuth();
  const { fetchProjects } = useProjects();

  useEffect(() => {
    if (user) {
      fetchProjects();
    }
  }, [user, fetchProjects]);

  return (
    <div className="flex min-h-screen testtest">
      {user && <Sidebar />}
      <div className={`flex-1 ${user ? 'ml-48' : ''}`}>
        {user && <Navbar />}
        <Routes>
          {user ? (
            <>
              <Route path="/create-project" element={<PrivateRoute><CreateProject /></PrivateRoute>} />
              <Route path="/view-projects" element={<PrivateRoute><ViewProjects /></PrivateRoute>} />
              <Route path="/projets/user/:userId" element={<PrivateRoute><ViewProjects /></PrivateRoute>} />
              <Route path="/profile" element={<PrivateRoute><UserProfile /></PrivateRoute>} />
              <Route path="/auth" element={<PrivateRoute><UserAccounts /></PrivateRoute>} />
              <Route path="/admin" element={<PrivateRoute><AdminPanel /></PrivateRoute>} />
              <Route path="/project-details/:id" element={<PrivateRoute><ProjectDetails /></PrivateRoute>} />
              <Route path="/add-detail/:id" element={<PrivateRoute><AddProjectDetail /></PrivateRoute>} />
              <Route path="/projets/:id/details/:detailId/dashboards" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
              <Route path="/settings" element={<PrivateRoute><UserProfile /></PrivateRoute>} />
              <Route path="/project-config/:id" element={<PrivateRoute><ProjectConfig /></PrivateRoute>} />
              <Route path="*" element={<Navigate to="/view-projects" replace />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </>
          )}
        </Routes>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <ProjectProvider>
          <CampaignProvider>
            <MainContent />
          </CampaignProvider>
        </ProjectProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
