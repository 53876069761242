import React, { useState } from 'react';
import '../css/bouton.css';
import axios from 'axios';
import Modal from './Modal';  // Importez le composant Modal
import AddDocument from './AddDocument';  // Importez le composant AddDocument
import { useMessageHandler } from '../hooks/useMessageHandler'; // Import du hook personnalisé
import '../css/tableau.css';
import config from '../config/config';

const ProjectDetailsTableauDocument = ({
  project,
  details,
  searchQueryDoc,
  setSearchQueryDoc,
  handleDownload,
  handleDeleteDocument,
  fetchProjectDetails,  // Cette fonction est passée en prop
  selectedDetailId,
  formatDate,
}) => {
  const [hoveredColumns, setHoveredColumns] = useState(null);
  const [isAddDocumentModalOpen, setIsAddDocumentModalOpen] = useState(false);  // État pour gérer l'ouverture du modal
  const {
    error,
    successMessage,
    setError,
    setSuccessMessage,
    clearMessagesAfterTimeout,
  } = useMessageHandler(); // Utilisation du hook
  const apiBaseUrl = config.FRONTEND.API_URL; 

  const handleFileChange = async (e, documentId) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.put(`${apiBaseUrl}/projets/${project.id}/details/${selectedDetailId}/documents/${documentId}`, formData, config);

      setSuccessMessage("Le document a été mis à jour avec succès.");
      fetchProjectDetails(); // Met à jour les détails du projet après modification
    } catch (error) {
      setError("Erreur lors de la mise à jour du document.");
    } finally {
      clearMessagesAfterTimeout(); // Efface les messages après un délai
    }
  };

  const toggleAddDocumentModal = () => {
    setIsAddDocumentModalOpen(!isAddDocumentModalOpen); // Ouvre ou ferme le modal
  };

  const filteredDocuments = details.flatMap(detail =>
    detail.id === selectedDetailId ? (detail.documents?.filter(doc => doc.nomDocument.toLowerCase().includes(searchQueryDoc.toLowerCase())) || []) : []
  );

  return (
    <div>
      <div className="flex justify-between items-center">
        <input
          className="flex-grow border p-2 rounded mr-4 custom-focus"
          type="text"
          placeholder="Rechercher par Nom du Document"
          value={searchQueryDoc}
          onChange={(e) => setSearchQueryDoc(e.target.value)}
        />
        {/* Bouton fas fa-plus pour ouvrir le modal */}
        <button
          onClick={toggleAddDocumentModal}  // Ouvre le modal
          className="text-white font-bold py-1 px-2 rounded text-2xl bg-green-800 hover:bg-green-700"
        >
          <i className="fas fa-plus"></i>
        </button>
      </div>

      {/* Affichage des messages d'erreur et de succès */}
      {error && <div className="bg-red-500 text-white p-2 rounded">{error}</div>}
      {successMessage && <div className="bg-green-500 text-white p-2 rounded">{successMessage}</div>}

      <div className="mt-6 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        {filteredDocuments.length === 0 ? (
          <div className="text-center p-4">
            <p className="text-lg">Aucun document trouvé.</p>
          </div>
        ) : (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider">
                  Document
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredDocuments.map((doc, index) => (
                <tr key={doc.id} onMouseEnter={() => setHoveredColumns(index)} onMouseLeave={() => setHoveredColumns(null)}>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {decodeURIComponent(doc.nomDocument)}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm space-x-2">
                    <button
                      onClick={() => handleDownload(selectedDetailId, doc.id, doc.nomDocument)}
                      className={`py-1 px-2 rounded ${hoveredColumns === index ? 'bouton' : 'bouton_cacher'}`}
                    >
                      Télécharger
                    </button>

                    <input
                      id={`file-upload-${doc.id}`}
                      type="file"
                      style={{ display: 'none' }}  // Cache l'input file
                      onChange={(e) => handleFileChange(e, doc.id)}  // Gestion de la sélection et l'upload du fichier
                    />
                    <button
                      onClick={() => handleDeleteDocument(selectedDetailId, doc.id)}
                      className={`py-1 px-2 rounded ml-4 ${hoveredColumns === index ? 'bouton' : 'bouton_cacher'}`}
                    >
                      Supprimer
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Modal pour ajouter un document */}
      <Modal isOpen={isAddDocumentModalOpen} onClose={toggleAddDocumentModal}>
        <AddDocument
          projectId={project.id}
          detailId={selectedDetailId}
          onUploadSuccess={() => {
            fetchProjectDetails(); // Rafraîchit les détails après ajout de document
            toggleAddDocumentModal(); // Ferme le modal après succès
          }}
          fetchProjectDetails={fetchProjectDetails}  // Passe la fonction comme prop
          onClose={toggleAddDocumentModal}
        />
      </Modal>
    </div>
  );
};

export default ProjectDetailsTableauDocument;
