import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useCampaigns } from '../CampaignContext';
import '../css/bouton.css';
import '../css/formulaire.css';
import '../css/tableau.css';
import GroupedDashboardImport from './GroupedDashboardImport';
import moment from 'moment';

const AddProjectDetail = ({ onAddSuccess, onClose }) => {
  const [detailData, setDetailData] = useState({
    DateDeDebut: '',
    DateDeFin: '',
    TitreCampagne: '',
    Description: '',
  });
  const [dashboards, setDashboards] = useState([{ url_Dashboard: '', nom: '', TypeDashboard: '' }]);
  const [message, setMessage] = useState('');
  const { id: projectId } = useParams(); // ID du projet
  const { addCampaign } = useCampaigns();

  const handleChange = (e) => {
    setDetailData({ ...detailData, [e.target.name]: e.target.value });
    setMessage('');
  };

  // Utilisez useEffect pour surveiller les mises à jour des dashboards
  useEffect(() => {
    setTimeout(() => {}, 100);
  }, [dashboards]);

  const prepareDashboardData = (dashboards) => {
    // Transformation des propriétés et filtrage des entrées vides
    const validDashboards = dashboards
      .map(dashboard => ({
        nom: dashboard.title, // Renomme 'title' en 'nom'
        url_Dashboard: dashboard.url, // Renomme 'url' en 'url_Dashboard'
        TypeDashboard: dashboard.TypeDashboard
      }))
      .filter(dashboard => dashboard.url_Dashboard && dashboard.nom && dashboard.TypeDashboard);
    return validDashboards;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const validDashboards = prepareDashboardData(dashboards);
      const token = localStorage.getItem('token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';

      // Formatage des dates en ISO avant l'envoi
      const formattedDetailData = {
        ...detailData,
        DateDeDebut: moment(detailData.DateDeDebut).format('DD-MM-YYYYTHH:mm'),
        DateDeFin: moment(detailData.DateDeFin).format('DD-MM-YYYYTHH:mm'),
      };

      // Étape 1 : Envoyer les détails de la campagne
      const detailResponse = await axios.post(`${apiBaseUrl}/projets/${projectId}/details`, formattedDetailData, config);
      const newCampaign = detailResponse.data;

      if (!newCampaign || !newCampaign.id) {
        throw new Error("Erreur lors de la création de la campagne.");
      }

      // Étape 2 : Envoyer les dashboards uniquement s'il y en a
      if (validDashboards.length > 0) {
        const dashboardPromises = validDashboards.map((dashboard) =>
          axios.post(`${apiBaseUrl}/projets/${projectId}/details/${newCampaign.id}/dashboards`, {
            ...dashboard,
            ID_DetailProjet: newCampaign.id
          }, config)
        );
        await Promise.all(dashboardPromises);
      } 
      // Mises à jour de l'état
      addCampaign(projectId, newCampaign); // Ajoute la campagne au contexte pour la mise à jour de l'interface
      setMessage('Détail ajouté avec succès.'); // Confirmation de l'ajout réussi
      setDetailData({ DateDeDebut: '', TitreCampagne: '', Description: '' }); // Réinitialise le formulaire
      setDashboards([{ url_Dashboard: '', nom: '', TypeDashboard: '' }]); // Réinitialise les champs de dashboard

      if (onAddSuccess) {
        onAddSuccess();
      }
      if (onClose) {
        onClose();
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout du détail du projet:", error.response ? error.response.data : error);
      setMessage(error.response?.data?.errors ? error.response.data.errors[0].msg : "Erreur lors de l'ajout du détail. Veuillez réessayer.");
    }
  };

  return (
    <div className="max-w-md mx-auto my-2 bg-white rounded max-w-2xl">
      {message && (
        <div className={message.includes('Erreur') ? "bg-red-500 text-white p-2 rounded" : "bg-green-500 text-white p-2 rounded"}>
          {message}
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 form-label">Titre de la Campagne <span className="required">*</span></label>
          <input
            type="text"
            name="TitreCampagne"
            value={detailData.TitreCampagne}
            onChange={handleChange}
            placeholder="Entrez le titre de la campagne"
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm custom-focus"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 form-label">Date de Début <span className="required">*</span></label>
          <input
            type="datetime-local"
            name="DateDeDebut"
            value={detailData.DateDeDebut}
            onChange={handleChange}
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm custom-focus"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 form-label">Date de Fin <span className="required">*</span></label>
          <input
            type="datetime-local"
            name="DateDeFin"
            value={detailData.DateDeFin}
            onChange={handleChange}
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm custom-focus"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Description</label>
          <textarea
            name="Description"
            value={detailData.Description}
            onChange={handleChange}
            placeholder="Ajoutez une description (facultatif)"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm custom-focus"
          ></textarea>
        </div>

        {/* Import groupé de dashboards */}
        <GroupedDashboardImport
          projectId={projectId}
          setDashboards={setDashboards}
          DateDeDebut={detailData.DateDeDebut} 
          DateDeFin={detailData.DateDeFin}
          TitreCampagne={detailData.TitreCampagne}
        />

        <div className="flex justify-between">
          <button type="submit" className="bouton_vert">Ajouter</button>
        </div>
      </form>
    </div>
  );
};

export default AddProjectDetail;
