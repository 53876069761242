import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../css/bouton.css';
import '../css/formulaire.css';
import moment from 'moment';
import config from '../config/config';

const GroupedDashboardImport = ({ projectId, setDashboards, DateDeDebut, DateDeFin, TitreCampagne }) => {
  const [showImportDashboard, setShowImportDashboard] = useState(false);
  const [localDashboards, setLocalDashboards] = useState([{ title: '', url: '', TypeDashboard: '' }]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [grafanaConfig, setGrafanaConfig] = useState({ urlGrafana: '', orgIdGrafana: '', grafanaUsername: '', grafanaPassword: '' });
  const [excludeTimeRange, setExcludeTimeRange] = useState(false);
  const apiBaseUrl = config.FRONTEND.API_URL; 

  // Convertir les dates sans modèle pour une interprétation ISO flexible
  const fromTime = moment(DateDeDebut).valueOf();
  const toTime = moment(DateDeFin).valueOf();

  useEffect(() => {
    const fetchGrafanaConfig = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/projets/${projectId}/grafanaConfig`);
        setGrafanaConfig(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération de la configuration Grafana :", error);
      }
    };
    fetchGrafanaConfig();
  }, [projectId, apiBaseUrl]);

  const updateDashboardUrls = (excludeTime) => {
    const updatedDashboards = localDashboards.map((dashboard) => {
      if (!dashboard.title && !dashboard.url && !dashboard.TypeDashboard) {
        return dashboard;
      }
  
      let dashboardUrl = dashboard.url.replace(/([?&]from=\d+)|([?&]to=\d+)|([?&]orgId=\d+)/g, '');
  
      if (excludeTime) {
        dashboardUrl += `${dashboardUrl.includes('?') ? '&' : '?'}orgId=${grafanaConfig.orgIdGrafana}`;
      } else {
        dashboardUrl += `${dashboardUrl.includes('?') ? '&' : '?'}from=${fromTime}&to=${toTime}&orgId=${grafanaConfig.orgIdGrafana}`;
      }
  
      return { ...dashboard, url: dashboardUrl };
    });
  
    setLocalDashboards(updatedDashboards);
    setDashboards(updatedDashboards);
  };
  

  const importDashboards = async () => {
    if (!projectId) {
        console.error("Erreur : projectId est manquant.");
        return;
    }

    // Vérifie si la configuration Grafana est complète avant d'envoyer la requête
    if (!grafanaConfig.urlGrafana || !grafanaConfig.orgIdGrafana || !grafanaConfig.grafanaUsername || !grafanaConfig.grafanaPassword) {
        console.error("Configuration Grafana incomplète au premier clic.");
        setErrorMessage("La configuration Grafana n'est pas encore chargée. Veuillez réessayer.");
        return;
    }

    setLoading(true);
    setErrorMessage('');

    setTimeout(async () => {
        try {
            const apiUrl = `${apiBaseUrl}/projets/${projectId}/dashboards`;

            const response = await axios.get(apiUrl);
            const dashboardsData = response.data;

            if (dashboardsData.length === 0) {
                console.warn("Aucun dashboard n'a pu être importé.");
                setErrorMessage("Aucun dashboard n'a pu être importé. Veuillez vérifier la configuration de votre projet.");
                setLoading(false);
                return;
            }

            const existingTitles = new Set(localDashboards.map(dashboard => dashboard.title));
            const existingUrls = new Set(localDashboards.map(dashboard => dashboard.url));

            const newDashboards = dashboardsData
                .filter(dashboard => !existingTitles.has(dashboard.title) && !existingUrls.has(dashboard.url))
                .map(dashboard => {
                    let dashboardUrl = dashboard.url;
                    if (dashboardUrl.includes('/grafana')) {
                        dashboardUrl = dashboardUrl.replace('/grafana', '');
                    }

                    if (!excludeTimeRange) {
                        dashboardUrl += `?from=${fromTime}&to=${toTime}&orgId=${grafanaConfig.orgIdGrafana}`;
                    } else {
                        dashboardUrl += `?orgId=${grafanaConfig.orgIdGrafana}`;
                    }

                    return {
                        title: dashboard.title,
                        url: `${grafanaConfig.urlGrafana}${dashboardUrl}`,
                        TypeDashboard: dashboard.TypeDashboard
                    };
                });

            console.log("Dashboards importés après filtrage :", newDashboards);

            // Supprime l'entrée vide avant d'ajouter aux dashboards
            const updatedDashboards = localDashboards.filter(
                dashboard => dashboard.title || dashboard.url || dashboard.TypeDashboard
            );

            const combinedDashboards = [...updatedDashboards, ...newDashboards];
            const lastEntry = combinedDashboards[combinedDashboards.length - 1];
            if (lastEntry.title && lastEntry.url && lastEntry.TypeDashboard) {
                combinedDashboards.push({ title: '', url: '', TypeDashboard: '' });
            }
         
            // Mise à jour de l'état
            setLocalDashboards(combinedDashboards);
            setDashboards(combinedDashboards);
        } catch (error) {
            console.error("Erreur lors de l'importation des dashboards :", error);

            if (error.response) {
                console.error("Réponse du serveur :", error.response.status, error.response.data);
            } else if (error.request) {
                console.error("Pas de réponse du serveur :", error.request);
            } else {
                console.error("Erreur de configuration :", error.message);
            }

            setErrorMessage("Erreur lors de l'importation des dashboards. Vérifiez la configuration Grafana et réessayez.");
        } finally {
            setLoading(false);
            setShowImportDashboard(true);
        }
    }, 500); // ⏳ Attente de 0.5 secondes avant d'exécuter l'import
};

const handleCancelImport = () => {
  console.log("Annulation de l'import des dashboards...");
  setLocalDashboards([{ title: '', url: '', TypeDashboard: '' }]); // Réinitialise les dashboards
  setDashboards([{ title: '', url: '', TypeDashboard: '' }]); // Met à jour le parent
};

  const handleExcludeTimeRangeChange = (e) => {
    const isChecked = e.target.checked;
    setExcludeTimeRange(isChecked);
    updateDashboardUrls(isChecked); // Met à jour les URLs des dashboards
  };
  

  const handleInputChange = (index, field, value) => {
    const updatedDashboards = localDashboards.map((dashboard, idx) =>
      idx === index ? { ...dashboard, [field]: value } : dashboard
    );
    // Vérifie si la dernière entrée est complètement remplie
    const lastEntry = updatedDashboards[updatedDashboards.length - 1];
    const isLastEntryEmpty = !lastEntry.title && !lastEntry.url && !lastEntry.TypeDashboard;
  
    // Ajoute une nouvelle entrée vide uniquement si la dernière entrée est remplie
    if (!isLastEntryEmpty && lastEntry.title && lastEntry.url && lastEntry.TypeDashboard) {
      updatedDashboards.push({ title: '', url: '', TypeDashboard: '' });
    }
  
    setLocalDashboards(updatedDashboards);
    setDashboards(updatedDashboards); // Transmet les mises à jour au composant parent
  };

  const handleDeleteEntry = (index, e) => {
    e.preventDefault();
    const updatedDashboards = localDashboards.filter((_, idx) => idx !== index);
    setLocalDashboards(updatedDashboards);
    setDashboards(updatedDashboards);
  };

  const isGrafanaConfigComplete = grafanaConfig.urlGrafana && grafanaConfig.orgIdGrafana && grafanaConfig.grafanaUsername && grafanaConfig.grafanaPassword;

  // Vérifie que les champs requis sont remplis pour activer le bouton d'import
  const areRequiredFieldsFilled = TitreCampagne && DateDeDebut && DateDeFin;

  return (
    <div className="space-y-0 border rounded-md">
      <div className="p-4 rounded-md cursor-pointer" onClick={() => setShowImportDashboard(!showImportDashboard)}>
        <h3 className="text-md font-semibold text-black flex items-center">
          <i className={`fas fa-chevron-${showImportDashboard ? 'up' : 'down'} w-4 h-4 mr-2`}></i>
          Import groupé de Dashboards (Optionnel)
        </h3>
      </div>

      {showImportDashboard && (
        <div className="p-4 bg-gray-100">
          {!isGrafanaConfigComplete && (
            <p className="text-red-500 mb-4 ml-4">Veuillez configurer le projet pour l’import Grafana avant de continuer.</p>
          )}

          {errorMessage && (
            <p className="text-red-500 mb-4 ml-4">{errorMessage}</p>
          )}

          {DateDeDebut && DateDeFin && (
            <div className="flex items-center mb-4 ml-4">
              <input
                type="checkbox"
                checked={excludeTimeRange}
                onChange={handleExcludeTimeRangeChange}
                className="mr-2"
              />
              <label className="text-red-500">Ne pas ajouter la timeRange</label>
            </div>
          )}

          <div className="overflow-y-auto" style={{ maxHeight: '500px' }}>
            {localDashboards.map((dashboard, index) => (
              <div key={index} className="p-4 bg-white rounded-md space-y-2 mb-2">
                <div className="flex justify-between items-center mb-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Entrée #{index + 1} *
                  </label>
                  {dashboard.title && dashboard.url && dashboard.TypeDashboard && (
                    <button onClick={(e) => handleDeleteEntry(index, e)} className="text-red-500">
                      <i className="fas fa-trash-alt"></i>
                    </button>
                  )}
                </div>

                <div className="flex space-x-2">
                  <input
                    type="text"
                    placeholder="L'URL du dashboard"
                    value={dashboard.url}
                    onChange={(e) => handleInputChange(index, 'url', e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
                  />
                </div>

                <div className="flex space-x-2">
                  <input
                    type="text"
                    placeholder="Titre du dashboard"
                    value={dashboard.title}
                    onChange={(e) => handleInputChange(index, 'title', e.target.value)}
                    className="w-2/3 px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
                  />
                  <select
                    value={dashboard.TypeDashboard}
                    onChange={(e) => handleInputChange(index, 'TypeDashboard', e.target.value)}
                    className="w-1/3 px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
                  >
                    <option value="">Type Dashboard</option>
                    <option value="SyntheticMonitoring">SyntheticMonitoring</option>
                    <option value="MeteoServices">MeteoServices</option>
                    <option value="InfraEndpoint">InfraEndpoint</option>
                    <option value="EndpointAppPerf">EndpointAppPerf</option>
                    <option value="DigitalExpMon">DigitalExpMon</option>
                    <option value="DigitalJourney">DigitalJourney</option>
                    <option value="Infrastructure">Infrastructure</option>
                    <option value="LoadTest">LoadTest</option>
                    <option value="Autre">Autre</option>
                  </select>
                </div>
              </div>
            ))}
          </div>

          <div className="flex space-x-2 ml-4">
            <button
              onClick={importDashboards}
              title={!areRequiredFieldsFilled ? 'Veuillez remplir les champs TitreCampagne, DateDeDebut, et DateDeFin.' : ''}
              className={`bouton_vert ${(!isGrafanaConfigComplete || !areRequiredFieldsFilled) ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={!isGrafanaConfigComplete || !areRequiredFieldsFilled || loading}
            >
              {loading ? "Importation en cours..." : "Importer les Dashboards (Optionnel)"}
            </button>

            {localDashboards.length > 1 && ( // Afficher uniquement si un dashboard a été importé
              <button
                onClick={handleCancelImport}
                className="bouton"
              >
                Annuler l'import
              </button>
            )}
          </div>

          <div className="mt-2 ml-4">
            <Link
              to={`/project-config/${projectId}`}
              className="text-blue-600 underline hover:text-blue-800"
            >
              Configurer le projet pour l’import Grafana
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupedDashboardImport;
