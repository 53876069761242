import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import axios from 'axios';
import config from './config/config';

const ProjectContext = createContext();

export const useProjects = () => useContext(ProjectContext);

export const ProjectProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const getApiBaseUrl = useCallback(() => config.FRONTEND.API_URL, []);

  const fetchProjects = useCallback(async (userId = null) => {
    const token = localStorage.getItem('token');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const apiBaseUrl = getApiBaseUrl();
    try {
      let url = `${apiBaseUrl}/projets?withAssociations=true`;
      if (userId) {
        url = `${apiBaseUrl}/projets/user/${userId}`;
      }
      // console.log(`Fetching projects from: ${url}`);
      const response = await axios.get(url, config);
      setProjects(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des projets. Veuillez réessayer.', error);
    }
  }, [getApiBaseUrl]);

  const addProject = useCallback((newProject) => {
    setProjects((prevProjects) => [...prevProjects, newProject]);
  }, []);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const updateProjectName = useCallback((projectId, newName) => {
    setProjects((prevProjects) =>
      prevProjects.map((project) =>
        project.id === projectId ? { ...project, NomDuProjet: newName } : project
      )
    );
  }, []);
  

  return (
    <ProjectContext.Provider value={{ projects, addProject, fetchProjects, updateProjectName }}>
      {children}
    </ProjectContext.Provider>
  );
};
