import React, { useState } from 'react';
import axios from 'axios';
import '../css/bouton.css';
import '../css/formulaire.css';

const GrafanaConfigForm = ({ formData, setFormData, handleChange, setMessage, setFormSuccess, formSuccess, isUrlVerified, setIsUrlVerified }) => {


  const [showImportGrafana, setShowImportGrafana] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({}); 


  const handleImportOrgName = async () => {
    const { urlGrafana, orgIdGrafana, grafanaUsername, grafanaPassword } = formData;

    try {
      const urlObj = new URL(urlGrafana);
      const baseGrafanaUrl = urlObj.origin + urlObj.pathname;

      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      // Envoi du nom d'utilisateur et du mot de passe Grafana au backend
      const response = await axios.get(`${apiBaseUrl}/proxy/grafana/orgs/${orgIdGrafana}`, {
        params: { baseGrafanaUrl, grafanaUsername, grafanaPassword }
      });

      if (response.data.error === "Unauthorized") {
        setFormErrors({
          grafanaUsername: "Identifiant ou mot de passe incorrect.",
          grafanaPassword: "Identifiant ou mot de passe incorrect."
        });
        setFormSuccess({}); // Réinitialiser les messages de succès
      } else {
        const orgName = response.data.name;
        setFormData({ ...formData, NomDuProjet: orgName });
        setFormSuccess({
          NomDuProjet: "Nom de l'organisation importé avec succès."
        });
        setFormErrors({}); // Réinitialiser les erreurs en cas de succès
      }
    } catch (error) {
      console.error("Erreur lors de l'importation du nom de l'organisation:", error);
      // Mettre l'erreur sur URL Grafana
      setFormErrors({
        urlGrafana: "Une erreur s'est produite lors de l'importation du nom de l'organisation. Veuillez vérifier si vous avez mis le bon orgId ou saisi la bonne URL de l'API Grafana."
      });
      setFormSuccess({});
    }
  };

  const handleValidateUrl = async () => {
    const { urlGrafana, orgIdGrafana, grafanaUsername, grafanaPassword } = formData;
  
    try {
      const urlObj = new URL(urlGrafana);
      const baseGrafanaUrl = urlObj.origin + urlObj.pathname;
  
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      const response = await axios.get(`${apiBaseUrl}/proxy/grafana/orgs/${orgIdGrafana}`, {
        params: { baseGrafanaUrl, grafanaUsername, grafanaPassword }
      });
  
      if (response.data.error === "Unauthorized") {
        setFormErrors({
          grafanaUsername: "Identifiant ou mot de passe incorrect.",
          grafanaPassword: "Identifiant ou mot de passe incorrect."
        });
        setFormSuccess({});
        setIsUrlVerified(false); // URL non vérifiée
      } else {
        setFormSuccess({
          urlGrafana: "L'URL Grafana est correctement formatée et accessible."
        });
        setFormErrors({});
        setIsUrlVerified(true); // URL vérifiée avec succès
      }
    } catch (error) {
      console.error("Erreur lors de la validation de l'URL Grafana:", error);
      setFormErrors({
        urlGrafana: "Erreur lors du contact avec Grafana. Veuillez vérifier que l'URL saisie pointe bien vers la racine de Grafana et que l'ID d'organisation (orgId) est correct."
      });
      setFormSuccess({});
      setIsUrlVerified(false); // URL non vérifiée
    }
  };
  
  // Désactivation des boutons si les champs nécessaires ne sont pas remplis
  const isValidateUrlDisabled = !formData.urlGrafana || !formData.grafanaUsername || !formData.grafanaPassword || !formData.orgIdGrafana;


  return (
    <>
      <div className="space-y-0 border rounded-md">
        <div
          className="p-4 rounded-md cursor-pointer"
          onClick={() => setShowImportGrafana(!showImportGrafana)}
        >
          <h3 className="text-md font-semibold text-black flex items-center">
            <i className={`fas fa-chevron-${showImportGrafana ? 'up' : 'down'} w-4 h-4 mr-2`}></i>
            Configuration  Grafana
          </h3>
        </div>
        {showImportGrafana && (
          <div className="p-4 bg-gray-100 space-y-4">
            <div className="form-group">
              <label className="block text-sm font-medium text-gray-700 form-label">URL Racine Grafana <span className="required" title="Ce champ est obligatoire">*</span></label>
              {formErrors.urlGrafana && (
                <p className="form-error text-red-500">{formErrors.urlGrafana}</p>
              )}
              {formSuccess.urlGrafana && (
                <p className="form-success text-green-600">{formSuccess.urlGrafana}</p>
              )}
            </div>
            <input
              type="text"
              name="urlGrafana"
              value={formData.urlGrafana}
              onChange={handleChange}
              placeholder="https://demo.atakama-technologies.com/grafana/"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
            />

            <div className="form-group">
              <label className="block text-sm font-medium text-gray-700 form-label">l'ID organisation (orgId) <span className="required" title="Ce champ est obligatoire">*</span></label>
              {formErrors.orgIdGrafana && (
                <p className="form-error text-red-500">{formErrors.orgIdGrafana}</p>
              )}
            </div>
            <input
              type="text"
              name="orgIdGrafana"
              value={formData.orgIdGrafana}
              onChange={handleChange}
              placeholder="Entrez l'OrgID manuellement"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
            />

            <div className="form-group">
              <label className="block text-sm font-medium text-gray-700 form-label">Nom d'utilisateur Grafana <span className="required" title="Ce champ est obligatoire">*</span></label>
              {formErrors.grafanaUsername && (
                <p className="form-error">{formErrors.grafanaUsername}</p>
              )}
            </div>
            <input
              type="text"
              name="grafanaUsername"
              value={formData.grafanaUsername}
              onChange={handleChange}
              placeholder="Entrez le nom d'utilisateur Grafana"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
            />

            <div className="form-group">
              <label className="block text-sm font-medium text-gray-700 form-label">Mot de passe Grafana <span className="required" title="Ce champ est obligatoire">*</span></label>
              {formErrors.grafanaPassword && (
                <p className="form-error">{formErrors.grafanaPassword}</p>
              )}
            </div>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                name="grafanaPassword"
                value={formData.grafanaPassword}
                onChange={handleChange}
                placeholder={showPassword ? "Entrez le mot de passe Grafana (visible)" : "Entrez le mot de passe Grafana (masqué)"}
                className="mt-1 block w-full px-3 py-2 pr-10 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
              />
              <div
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} text-gray-500`}></i>
              </div>
            </div>

            <div className="mt-4">
              <button
                  type="button"
                  className={`bouton_vert_grafana ${isValidateUrlDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                  onClick={handleValidateUrl}
                  disabled={isValidateUrlDisabled}
                  title={isValidateUrlDisabled ? "Veuillez remplir tous les champs Grafana pour utiliser cette option" : ""}
                >
                  Vérifier l'URL
                </button>
                
                <button
                  type="button"
                  className={`bouton_vert_grafana ml-2 ${(!isUrlVerified ? 'opacity-50 cursor-not-allowed' : '')}`}
                  onClick={handleImportOrgName}
                  disabled={!isUrlVerified}
                  title={!isUrlVerified ? "Veuillez d'abord vérifier l'URL Grafana" : ""}
                >
                  Importer le nom de l'organisation
                </button>
              </div>

          </div>
        )}
      </div>
    </>
  );
};

export default GrafanaConfigForm;
