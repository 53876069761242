import React from 'react';

const TabsGrafanaConfig = ({ selectedTab, handleTabChange, showGeneralTabAlert }) => {
  return (
    <div className="border-b border-gray-300 my-4">
      <nav className="flex space-x-4">
        <button
          className={`py-2 px-4 ${selectedTab === 'general' ? 'border-b-2 border-green-800' : ''}`}
          onClick={() => handleTabChange('general')}
        >
          Informations Générales
          {showGeneralTabAlert && <span className="text-red-500 ml-2">●</span>}
        </button>
        <button
          className={`py-2 px-4 ${selectedTab === 'grafana' ? 'border-b-2 border-green-800' : ''}`}
          onClick={() => handleTabChange('grafana')}
        >
          Configuration Grafana
        </button>
      </nav>
    </div>
  );
};

export default TabsGrafanaConfig;
