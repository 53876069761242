import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useCampaigns } from '../CampaignContext';
import config from '../config/config';

const AddDashboard = ({ onAddSuccess, projectId, setDashboards }) => {
  const { id, detailId } = useParams();
  const { campaigns } = useCampaigns();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [DateDeDebut, setDateDeDebut] = useState('');
  const [DateDeFin, setDateDeFin] = useState('');
  const [excludeTimeRange, setExcludeTimeRange] = useState(false);
  const [localDashboards, setLocalDashboards] = useState([{ title: '', url: '', TypeDashboard: '' }]);
  const [grafanaConfig, setGrafanaConfig] = useState({ urlGrafana: '', orgIdGrafana: '' });
  const [existingDashboards, setExistingDashboards] = useState([]);
  const apiBaseUrl = config.FRONTEND.API_URL; 
  const [hasImported, setHasImported] = useState(false);

  useEffect(() => {
    if (campaigns[id]) {
      const currentCampaign = campaigns[id].find(
        (campaign) => campaign.id === parseInt(detailId)
      );
      if (currentCampaign) {
        // console.log("Dates brutes de la campagne :", currentCampaign); 

        const formattedStartDate = parseBackendDate(currentCampaign.DateDeDebut);
        const formattedEndDate = parseBackendDate(currentCampaign.DateDeFin);

        if (formattedStartDate && formattedEndDate) {
          // console.log("Dates formatées après parseBackendDate :", {
          //   formattedStartDate,
          //   formattedEndDate,
          // });
          setDateDeDebut(formattedStartDate);
          setDateDeFin(formattedEndDate);
        } else {
          console.error("Dates invalides après formatage :", {
            DateDeDebut: currentCampaign.DateDeDebut,
            DateDeFin: currentCampaign.DateDeFin,
          });
        }
      } else {
        console.error("Aucune campagne correspondante trouvée dans campaigns");
      }
    } else {
      console.error("Aucune donnée trouvée pour l'ID de projet :", id);
    }
  }, [campaigns, id, detailId]);

  const parseBackendDate = (date) => {
    if (!date) {
      console.error("Date vide ou non définie :", date);
      return ''; // Retourne une chaîne vide si la date est invalide
    }

    if (date.includes('T') && !isNaN(Date.parse(date))) {
      //console.log("Date déjà au format ISO :", date);
      return date;
    }

    const regex = /(\d{2})\/(\d{2})\/(\d{4})\s(\d{2}:\d{2})/;
    const match = date.match(regex);

    if (!match) {
      console.error("Format de date non reconnu :", date);
      return ''; // Retourne une chaîne vide si le format ne correspond pas
    }

    const [, day, month, year, time] = match;
    const isoDate = `${year}-${month}-${day}T${time}`;

    if (isNaN(Date.parse(isoDate))) {
      console.error("Date convertie invalide :", isoDate);
      return ''; // Retourne une chaîne vide si la conversion échoue
    }

    // console.log("Date convertie au format ISO :", isoDate);
    return isoDate;
  };

  const handleDateChange = (e, setDate) => {
    const { name, value } = e.target;
    const formattedDate = toISOFormat(value);
  
    if (!formattedDate) return;
  
    // Vérification croisée avant mise à jour
    if (name === "DateDeDebut" && DateDeFin && formattedDate > DateDeFin) {
      setMessage("La date de début ne peut pas être postérieure à la date de fin.");
      return;
    }
  
    if (name === "DateDeFin" && DateDeDebut && formattedDate < DateDeDebut) {
      setMessage("La date de fin ne peut pas être antérieure à la date de début.");
      return;
    }
  
    setMessage(""); // Réinitialise le message si tout va bien
    setDate(formattedDate);
  };
  


  useEffect(() => {
    const fetchGrafanaConfig = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/projets/${projectId}/grafanaConfig`);
        setGrafanaConfig(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération de la configuration Grafana :", error);
      }
    };
    fetchGrafanaConfig();
  }, [projectId, apiBaseUrl]);

  const updateDashboardUrls = (excludeTime) => {
    const fromTime = moment(DateDeDebut).valueOf();
    const toTime = moment(DateDeFin).valueOf();

    const updatedDashboards = localDashboards.map((dashboard) => {
      if (!dashboard.url || !dashboard.title) return dashboard;

      let dashboardUrl = dashboard.url.replace(/([?&]from=\d+)|([?&]to=\d+)|([?&]orgId=\d+)/g, '');

      dashboardUrl += excludeTime
        ? `${dashboardUrl.includes('?') ? '&' : '?'}orgId=${grafanaConfig.orgIdGrafana}`
        : `${dashboardUrl.includes('?') ? '&' : '?'}from=${fromTime}&to=${toTime}&orgId=${grafanaConfig.orgIdGrafana}`;

      return {
        ...dashboard,
        url: dashboardUrl,
      };
    });

    setLocalDashboards(updatedDashboards);
    setDashboards(updatedDashboards);
    setHasImported(true);
  };

  const handleExcludeTimeRangeChange = (e) => {
    setExcludeTimeRange(e.target.checked);
    updateDashboardUrls(e.target.checked);
  };


  const toISOFormat = (date) => {
    if (!date) return null; // Gère les cas où la date est vide
    if (date.includes('T')) {
      return date; // Retourne la date directement si elle est déjà au format ISO
    }

    const regex = /(\d{2})\/(\d{2})\/(\d{4})\s(\d{2}:\d{2})/;
    const match = date.match(regex);

    if (!match) {
      console.error(`Format de date invalide : ${date}`);
      return null;
    }

    const [day, month, year, time] = match.slice(1);
    return `${year}-${month}-${day}T${time}`;
  };



  const handleInputChange = (index, field, value) => {
    const updatedDashboards = localDashboards.map((dashboard, idx) =>
      idx === index ? { ...dashboard, [field]: value } : dashboard
    );

    const lastEntry = updatedDashboards[updatedDashboards.length - 1];
    if (lastEntry.title && lastEntry.url && lastEntry.TypeDashboard) {
      updatedDashboards.push({ title: '', url: '', TypeDashboard: '' });
    }

    setLocalDashboards(updatedDashboards);
  };

  const handleDeleteEntry = (index) => {
    const updatedDashboards = localDashboards.filter((_, idx) => idx !== index);
    setLocalDashboards(updatedDashboards);
    setDashboards(updatedDashboards);
  };

  useEffect(() => {
    const fetchExistingDashboards = async () => {
      try {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}` } };
  
        const response = await axios.get(
          `${apiBaseUrl}/projets/${projectId}/details/${detailId}/dashboards`,
          config
        );
  
        const existingDashboardsData = response.data.map((dashboard) => ({
          title: dashboard.nom,
          url: dashboard.url_Dashboard,
          TypeDashboard: dashboard.TypeDashboard,
        }));
  
        setExistingDashboards(existingDashboardsData); // Stocker les dashboards existants pour la comparaison
      } catch (error) {
        console.error("Erreur lors de la récupération des dashboards existants :", error);
      }
    };
  
    fetchExistingDashboards();
  }, [apiBaseUrl, projectId, detailId]);
  
  
  const importDashboards = async () => {
    setLoading(true);
    setMessage('');
    try {
      const response = await axios.get(`${apiBaseUrl}/projets/${projectId}/dashboards`);
      const dashboardsData = response.data;
  
      if (dashboardsData.length === 0) {
        setMessage("Aucun dashboard trouvé.");
        setHasImported(false);
        return;
      }
  
      const fromTime = moment(DateDeDebut).valueOf();
      const toTime = moment(DateDeFin).valueOf();
  
      const newDashboards = [];
  
      dashboardsData.forEach((dashboard) => {
        let dashboardUrl = dashboard.url.replace(/\/grafana/, '');
        dashboardUrl += excludeTimeRange
          ? `?orgId=${grafanaConfig.orgIdGrafana}`
          : `?from=${fromTime}&to=${toTime}&orgId=${grafanaConfig.orgIdGrafana}`;
  
        const dashboardToImport = {
          title: dashboard.title,
          url: `${grafanaConfig.urlGrafana}${dashboardUrl}`,
          TypeDashboard: dashboard.TypeDashboard || 'Autre',
        };
  
        // Vérifier si le dashboard existe déjà dans `existingDashboards`
        const isDuplicate = existingDashboards.some(
          (existing) =>
            existing.url === dashboardToImport.url && existing.title === dashboardToImport.title
        );
  
        if (!isDuplicate) {
          newDashboards.push(dashboardToImport); // Ajouter seulement les nouveaux dashboards
        }
      });
  
      if (newDashboards.length === 0) {
        setMessage("Tous les Dashboards sont déjà présents. Aucun nouveau dashboard à importer.");
        setHasImported(false);
        return;
      }
  
      const updatedDashboards = [
        ...localDashboards.filter((dashboard) => dashboard.title || dashboard.url || dashboard.TypeDashboard),
        ...newDashboards,
        { title: '', url: '', TypeDashboard: '' },
      ];
  
      setLocalDashboards(updatedDashboards);
      setDashboards(updatedDashboards);
      setMessage(`${newDashboards.length} nouveaux dashboards importés avec succès.`);
      setHasImported(true);
    } catch (error) {
      console.error("Erreur lors de l'importation :", error);
      setMessage("Erreur lors de l'importation des dashboards.");
      setHasImported(false);
    } finally {
      setLoading(false);
    }
  };
  
  
  
  
  const handleAddDashboards = async () => {
    if (!grafanaConfig.urlGrafana || !grafanaConfig.orgIdGrafana) {
      setMessage("Erreur : la configuration Grafana est incomplète. Veuillez vérifier l'URL et l'ID d'organisation.");
      return;
    }
  
    if (!DateDeDebut || !DateDeFin) {
      setMessage("Erreur : veuillez renseigner une Date de Début et une Date de Fin.");
      return;
    }
  
    const validDashboards = localDashboards.filter(
      (dashboard) => dashboard.title && dashboard.url && dashboard.TypeDashboard
    );
  
    const filteredDashboards = validDashboards.filter((dashboard) => !dashboard.isDuplicate);
  
    if (filteredDashboards.length === 0) {
      setMessage("Erreur : aucun dashboard valide à ajouter.");
      return;
    }
  
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { Authorization: `Bearer ${token}` } };
  
      const dashboardsToSend = filteredDashboards.map((dashboard) => ({
        nom: dashboard.title,
        url_Dashboard: dashboard.url,
        TypeDashboard: dashboard.TypeDashboard,
        ID_DetailProjet: Number(detailId),
      }));
  
      await axios.post(
        `${apiBaseUrl}/projets/${id}/details/${detailId}/dashboards`,
        { dashboards: dashboardsToSend },
        config
      );
  
      setMessage("Succès : les dashboards ont été ajoutés avec succès !");
      setLocalDashboards([{ title: "", url: "", TypeDashboard: "" }]);
      if (onAddSuccess) onAddSuccess();
    } catch (error) {
      console.error("Erreur lors de l'ajout des dashboards :", error);
      setMessage(
        error.response && error.response.data?.error
          ? `Erreur : ${error.response.data.error}`
          : "Erreur : l'ajout des dashboards a échoué. Veuillez réessayer."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCancelImport = () => {
    console.log("Annulation de l'import des dashboards...");
    const reset = [{ title: '', url: '', TypeDashboard: '' }];
    setLocalDashboards(reset);
    setDashboards(reset);
    setMessage('Importation annulée.');
    setHasImported(false); 
  };
  
  

  return (
    <div className="max-w-md mx-auto my-2 bg-white rounded max-w-2xl space-y-6">
      {(!grafanaConfig.urlGrafana || !grafanaConfig.orgIdGrafana) && (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4">
          <p>
            La configuration Grafana est incomplète. <a href={`/project-config/${projectId}`} className="underline text-blue-600">Cliquez ici pour configurer Grafana</a>.
          </p>
        </div>
      )}
      {message && (
        <div
          className={
            message.includes('Erreur')
              ? 'bg-red-500 text-white p-2 rounded'
              : 'bg-green-100 border-l-4 border-green-500 text-green-800 p-2 my-2 rounded'
          }
        >
          {message}
        </div>
      )}
      {DateDeDebut && DateDeFin && (
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            checked={excludeTimeRange}
            onChange={handleExcludeTimeRangeChange}
            className="mr-2"
          />
          <label className="text-red-500">Ne pas ajouter la timeRange</label>
        </div>
      )}

    <div className="flex space-x-4">
      <div className="w-1/2">
        <label className="block text-sm font-medium text-gray-700">
          Date de Début <span className="text-red-500">*</span>
        </label>
        <input
          type="datetime-local"
          name="DateDeDebut"
          value={DateDeDebut || ''}
          onChange={(e) => handleDateChange(e, setDateDeDebut)}
          required
          max={DateDeFin || undefined}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm custom-focus"
        />
      </div>

      <div className="w-1/2">
        <label className="block text-sm font-medium text-gray-700">
          Date de Fin <span className="text-red-500">*</span>
        </label>
        <input
          type="datetime-local"
          name="DateDeFin"
          value={DateDeFin || ''}
          onChange={(e) => handleDateChange(e, setDateDeFin)}
          required
          min={DateDeDebut || undefined}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm custom-focus"
        />
      </div>
    </div>

      <div className="overflow-y-auto" style={{ maxHeight: '400px' }}>
      <div className="p-4 bg-gray-100">
        {localDashboards.map((dashboard, index) => (
          <div
            key={index}
            className={`bg-white rounded-md space-y-2 mb-2 ${dashboard.isDuplicate ? 'bg-red-100' : ''
              }`}
          >
            <div className="flex justify-between items-center mb-2">
              <label className="block text-sm font-medium text-gray-700">
                Entrée #{index + 1}
              </label>
              {dashboard.isDuplicate && (
                <span className="text-red-500 text-sm ml-auto">Doublon détecté</span>
              )}
              {dashboard.title && dashboard.url && dashboard.TypeDashboard && (
                <button
                  onClick={() => handleDeleteEntry(index)}
                  className="text-red-500"
                >
                  <i className="fas fa-trash-alt"></i>
                </button>
              )}
            </div>
            <div className="flex space-x-2">
              <input
                type="text"
                placeholder="L'URL du dashboard"
                value={dashboard.url}
                onChange={(e) => handleInputChange(index, 'url', e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
              />
            </div>
            <div className="flex space-x-2">
              <input
                type="text"
                placeholder="Titre du dashboard"
                value={dashboard.title}
                onChange={(e) => handleInputChange(index, 'title', e.target.value)}
                className="w-2/3 px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
              />
              <select
                value={dashboard.TypeDashboard}
                onChange={(e) => handleInputChange(index, 'TypeDashboard', e.target.value)}
                className="w-1/3 px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
              >
                <option value="">Type Dashboard</option>
                <option value="SyntheticMonitoring">Synthetic Monitoring</option>
                <option value="MeteoServices">Météo des Services</option>
                <option value="InfraEndpoint">Infra Endpoint</option>
                <option value="EndpointAppPerf">Endpoint Application Performance</option>
                <option value="DigitalExpMon">Digital Experience Monitoring</option>
                <option value="DigitalJourney">Digital Journey</option>
                <option value="Infrastructure">Infrastructure</option>
                <option value="LoadTest">Load Test</option>
                <option value="Autre">Autre</option>
              </select>
            </div>
          </div>
        ))}

      </div>

      <div className="sticky bottom-0 bg-white py-3 mt-4 z-10 flex justify-center space-x-4">
        <button
          onClick={importDashboards}
          className={`bouton_vert ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={loading}
        >
          {loading ? "Importation en cours..." : "Importer les Dashboards"}
        </button>

        <button
          type="button"
          onClick={handleAddDashboards}
          className={`bouton_vert ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={loading}
        >
          {loading ? 'Ajout en cours...' : 'Ajouter les Dashboards'}
        </button>

        {hasImported && (
          <button
            type="button"
            onClick={handleCancelImport}
            className="bouton"
          >
            Annuler l'import
          </button>
        )}

      </div>

      </div>


    </div>
  );
};


export default AddDashboard;
