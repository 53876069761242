import React, { useState } from 'react';
import axios from 'axios';
import '../css/bouton.css';
import '../css/tableau.css';
import config from '../config/config';

const AddDocument = ({ projectId, detailId, onUploadSuccess, onClose, fetchProjectDetails }) => {
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const apiBaseUrl = config.FRONTEND.API_URL; 

    // Définir les tailles maximales par type de fichier
    const maxFileSizes = {
        // Documents Office
        'application/msword': 10 * 1024 * 1024, // .doc - 10 Mo
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 10 * 1024 * 1024, // .docx - 10 Mo
        'application/vnd.ms-excel': 15 * 1024 * 1024, // .xls - 15 Mo
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 15 * 1024 * 1024, // .xlsx - 15 Mo
        'application/vnd.ms-powerpoint': 20 * 1024 * 1024, // .ppt - 20 Mo
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 20 * 1024 * 1024, // .pptx - 20 Mo
        'application/pdf': 25 * 1024 * 1024, // .pdf - 25 Mo

        // Images
        'image/jpeg': 5 * 1024 * 1024, // .jpeg - 5 Mo
        'image/png': 5 * 1024 * 1024, // .png - 5 Mo

        // Vidéos
        'video/mp4': 100 * 1024 * 1024, // .mp4 - 100 Mo
        'video/mpeg': 100 * 1024 * 1024, // .mpeg - 100 Mo
        'video/x-matroska': 100 * 1024 * 1024, // .mkv - 100 Mo

        // Archives
        'application/zip': 50 * 1024 * 1024, // .zip - 50 Mo
        'application/x-tar': 50 * 1024 * 1024, // .tar - 50 Mo
        'application/x-rar-compressed': 50 * 1024 * 1024, // .rar - 50 Mo
        'application/x-7z-compressed': 50 * 1024 * 1024, // .7z - 50 Mo

        // Fichiers supplémentaires
        'application/json': 1 * 1024 * 1024, // .json - 1 Mo
        'application/xml': 1 * 1024 * 1024, // .xml - 1 Mo
        'text/csv': 1 * 1024 * 1024, // .csv - 1 Mo
        'application/octet-stream': 5 * 1024 * 1024, // .side, .bat - 5 Mo
        'text/x-java-source': 5 * 1024 * 1024, // .java - 5 Mo
    };

    // Liste complète des types de fichiers autorisés
    const allowedFileTypes = [
        // Documents Office
        'application/msword', // .doc
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
        'application/vnd.ms-excel', // .xls
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
        'application/vnd.ms-powerpoint', // .ppt
        'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
        'application/pdf', // .pdf

        // Images
        'image/jpeg', // .jpeg, .jpg
        'image/png', // .png

        // Vidéos
        'video/mp4', // .mp4
        'video/mpeg', // .mpeg
        'video/x-matroska', // .mkv

        // Archives
        'application/zip', // .zip
        'application/x-tar', // .tar
        'application/x-rar-compressed', // .rar
        'application/x-7z-compressed', // .7z

        // Fichiers supplémentaires
        'application/json', // .json
        'application/xml', // .xml
        'text/csv', // .csv
        'application/octet-stream', // .side, .bat
        'text/x-java-source', // .java
    ];

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            const fileType = selectedFile.type;
            const maxFileSize = maxFileSizes[fileType] || 10 * 1024 * 1024; // Utilise la limite spécifique ou 10 Mo par défaut

            // Vérifier la taille du fichier
            if (selectedFile.size > maxFileSize) {
                setError(`Le fichier est trop volumineux. La taille maximale pour ce type de fichier est de ${maxFileSize / (1024 * 1024)} Mo.`);
                setFile(null);
                return;
            }

            // Vérifier le type de fichier
            if (!allowedFileTypes.includes(fileType)) {
                setError('Le type de fichier sélectionné n\'est pas autorisé.');
                setFile(null);
                return;
            }

            setFile(selectedFile);
            setError(''); // Clear error message when user selects a new file
            setSuccessMessage(''); // Clear success message when user selects a new file
        }
    };

    const handleUpload = async () => {
        if (!file) {
            setError("Veuillez sélectionner un fichier à uploader.");
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        setUploading(true);
        setError('');

        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            };
            const response = await axios.post(`${apiBaseUrl}/projets/${projectId}/details/${detailId}/documents/upload`, formData, config);

            setUploading(false);
            if (response.data) {
                onUploadSuccess(response.data, detailId); // Appel de la fonction pour mettre à jour l'état
                if (typeof fetchProjectDetails === 'function') {
                    await fetchProjectDetails();  // Récupérer à nouveau les détails du projet après l'upload
                }
                setFile(null); // Reset the file input after successful upload
                onClose(); // Close the modal after a successful upload
            } else {
                setError("Une réponse inattendue a été reçue du serveur.");
            }
        } catch (error) {
            console.error('Erreur lors de l\'upload du fichier:', error);
            setError(`Erreur lors de l'upload du fichier: ${error.message}`);
            setUploading(false);
        }
    };

    return (
        <div className="max-w-md mx-auto bg-white p-6 rounded-lg max-w-3xl">
            <form onSubmit={(e) => { e.preventDefault(); handleUpload(); }} className="space-y-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Document:</label>
                    <input
                        type="file"
                        onChange={handleFileChange}
                        aria-label="Sélectionner un fichier à télécharger"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm custom-focus"
                    />
                </div>
                <button
                    type="submit"
                    disabled={uploading}
                    aria-busy={uploading}
                    aria-label={uploading ? 'Chargement en cours' : 'Uploader le fichier'}
                    className={`bouton_vert ${uploading ? 'bg-gray-500' : ''}`}
                >
                    {uploading ? 'Chargement...' : 'Uploader'}
                </button>
            </form>
            {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4" role="alert">{error}</div>}
            {successMessage && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-4" role="status">{successMessage}</div>}
        </div>
    );
};

export default AddDocument;
