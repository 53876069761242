import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { useProjects } from '../ProjectContext';
import { useCampaigns } from '../CampaignContext';
import Modal from './Modal';
import CreateProject from './CreateProject';
import axios from 'axios';
import _ from 'lodash';
import '../css/bouton.css';
import '../css/tableau.css';
import { useSortConfig, useSortedData, getSortIcon } from './SortUtils';
import { useMessageHandler } from '../hooks/useMessageHandler';

const ViewProjects = () => {
  const { error, successMessage, setError, setSuccessMessage, clearMessagesAfterTimeout } = useMessageHandler();
  const { user } = useAuth();
  const { projects, fetchProjects } = useProjects();
  const { fetchCampaigns } = useCampaigns();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [hoveredColumn, setHoveredColumn] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [filteredUser, setFilteredUser] = useState(null);

  const { sortConfig, requestSort } = useSortConfig();

  const fetchUsers = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      const response = await axios.get(`${apiBaseUrl}/users`, config);
      setUsers(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des utilisateurs:', error);
    }
  }, []);

  useEffect(() => {
    if (user.role === 'ADMIN') {
      fetchUsers();
    }
  }, [user.role, fetchUsers]);

  useEffect(() => {
    const fetchFilteredProjects = async () => {
      if (selectedUserId === null) {
        await fetchProjects();
        setFilteredUser(null);
      } else {
        await fetchProjects(selectedUserId);
        const user = users.find(u => u.id === parseInt(selectedUserId));
        setFilteredUser(user);
      }
    };

    fetchFilteredProjects();
  }, [selectedUserId, fetchProjects, users]);

  useEffect(() => {
    if (selectedProjectId) {
      fetchCampaigns(selectedProjectId);
    }
  }, [selectedProjectId, fetchCampaigns]);

  const toggleModal = useCallback(() => {
    setIsModalOpen(prev => !prev);
  }, []);

  const toggleFilterModal = useCallback(() => {
    setIsFilterModalOpen(prev => !prev);
  }, []);

  const handleProjectAdd = useCallback(() => {
    fetchProjects(selectedUserId);
    toggleModal();
  }, [fetchProjects, toggleModal, selectedUserId]);

  const toggleAssignModal = useCallback(() => {
    setIsAssignModalOpen(prev => !prev);
  }, []); // Déplacé ici pour éviter no-use-before-define

  const handleSearchChange = useMemo(
    () =>
      _.debounce((event) => {
        setSearchTerm(event.target.value);
      }, 300),
    []
  );

  const handleUserChange = useCallback((event) => {
    const userId = event.target.value;
    setSelectedUserId(userId === 'all' ? null : userId);
    setIsFilterModalOpen(false);
  }, []);

  const handleMouseEnter = useCallback((index) => {
    setHoveredColumn(index);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoveredColumn(null);
  }, []);

  const handleDelete = useCallback(async (projectId) => {
    try {
      const token = localStorage.getItem('token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      await axios.delete(`${apiBaseUrl}/projets/${projectId}`, config);
      fetchProjects(selectedUserId);
    } catch (error) {
      setError('Erreur lors de la suppression du projet. Veuillez réessayer.');
    }
  }, [fetchProjects, selectedUserId, setError]);

  const handleEditClick = (project) => {
    navigate(`/project-config/${project.id}`);
  };


  const handleUnassign = useCallback(async (projectId, userIdToUnassign) => {
    try {
      const token = localStorage.getItem('token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      await axios.delete(`${apiBaseUrl}/projets/unassign/${projectId}/${userIdToUnassign}`, config);
      fetchProjects(selectedUserId);
    } catch (error) {
      setError('Erreur lors de la dissociation du projet. Veuillez réessayer.');
    }
  }, [fetchProjects, selectedUserId, setError]);

  const assignProjectToUser = useCallback(async (userId) => {
    if (!userId || !selectedProjectId) return;

    const selectedUser = users.find(u => u.id === parseInt(userId));

    if (window.confirm(`Êtes-vous sûr de vouloir assigner le projet à l'utilisateur ${selectedUser.prenom} ${selectedUser.nom} ?`)) {
      try {
        const token = localStorage.getItem('token');
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const apiBaseUrl = process.env.REACT_APP_API_URL || '';
        const response = await axios.post(`${apiBaseUrl}/projets/assign/${selectedProjectId}`, { userId }, config);

        if (response.status === 200) {
          setSuccessMessage('Utilisateur assigné avec succès.');
          clearMessagesAfterTimeout();
          toggleAssignModal();
          fetchProjects(selectedUserId);
        } else {
          setError(`L'utilisateur ${selectedUser.prenom} ${selectedUser.nom} est déjà assigné à ce projet.`);
          clearMessagesAfterTimeout();
        }
      } catch (error) {
        setError(`L'utilisateur ${selectedUser.prenom} ${selectedUser.nom} est déjà assigné à ce projet.`);
        clearMessagesAfterTimeout();
        console.error(error);
      }
    }
  }, [selectedProjectId, fetchProjects, toggleAssignModal, selectedUserId, users, setSuccessMessage, setError, clearMessagesAfterTimeout]);

  const confirmDelete = useCallback((projectId, projectName) => {
    if (window.confirm(`Êtes-vous sûr de vouloir supprimer le projet "${projectName}" ?`)) {
      handleDelete(projectId);
    }
  }, [handleDelete]);

  const confirmUnassign = useCallback((projectId, projectName, userIdToUnassign) => {
    if (window.confirm(`Êtes-vous sûr de vouloir dissocier l'utilisateur du projet "${projectName}" ?`)) {
      handleUnassign(projectId, userIdToUnassign);
    }
  }, [handleUnassign]);

  
  const openAssignModal = useCallback((projectId) => {
    setSelectedProjectId(projectId);
    toggleAssignModal();
  }, [toggleAssignModal]);

  const handleUserChangeForAssignment = useCallback((event) => {
    assignProjectToUser(event.target.value);
  }, [assignProjectToUser]);

  const filterProjects = () => {
    return projects.filter((project) =>
      project.NomDuProjet.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.Titre.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.Commentaire.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const filteredSortedProjects = useSortedData(filterProjects(), sortConfig);

  if (!user) return null;

  return (
    <div className="flex flex-row min-h-screen">
      <div className="flex-grow" style={{ backgroundColor: '#F5F5F5' }}>
        <div className="w-full pt-6" style={{ height: '25vh', backgroundColor: '#166534' }}>
          <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 mt-20">
            <div className="bg-white p-6 shadow rounded-lg mt-8">
              <h1 className="text-2xl font-bold text-gray-800 my-6">
                {user.role === 'ADMIN' 
                  ? (filteredUser ? `Tous les projets de ${filteredUser.prenom} ${filteredUser.nom}` : 'La liste de projets de tous les utilisateurs') 
                  : 'Votre liste de projets'}
              </h1>
              {error && <div className="bg-red-500 text-white p-2 rounded">{error}</div>}
              {successMessage && <div className="bg-green-500 text-white p-2 rounded">{successMessage}</div>}
              <div className="flex justify-between items-center mb-4">
                  <input
                    type="text"
                    placeholder="Rechercher par Projet ou Titre"
                    className="search-bar border p-2 rounded mr-4 custom-focus"
                    onChange={handleSearchChange}
                  />
                  <div className="flex space-x-2">
                      {user.role === 'ADMIN' && (
                        <button
                          onClick={toggleFilterModal}
                          className={`p-2 pr-3 bg-green-800 ${selectedUserId !== null ? 'text-gray-500 bg-gray-200 rounded' : 'text-white '}  hover:bg-green-700  rounded`}
                        >
                          <i className="fas fa-shapes"></i> 
                        </button>
                      )}
                      <button 
                        onClick={toggleModal} 
                        className="text-white font-bold py-1 px-2 rounded text-2xl bg-green-800 hover:bg-green-700">
                        <i className="fas fa-plus"></i>
                      </button>
                  </div>
              </div>
              <Modal isOpen={isFilterModalOpen} onClose={toggleFilterModal} title={`Filtrer par utilisateur`}>
                <div className="p-4">
                  <select onChange={handleUserChange} className="form-select block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm">
                    <option value="">Sélectionnez un utilisateur</option>
                    <option value="all">Afficher tous les projets</option>
                    {users.map((user) => (
                      <option key={user.id} value={user.id}>{user.prenom} {user.nom}</option>
                    ))}
                  </select>
                </div>
              </Modal>
              <Modal isOpen={isModalOpen} onClose={toggleModal}  title="Créer un nouveau projet">
                <CreateProject onClose={toggleModal} onProjectAdd={handleProjectAdd} />
              </Modal>


              <Modal isOpen={isAssignModalOpen} onClose={toggleAssignModal} title={`Assigner le projet à un utilisateur`}>
                <div className="p-4">
                 
                  <select onChange={handleUserChangeForAssignment} className="form-select block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm">
                    <option value="">Sélectionnez un utilisateur</option>
                    {users.map((user) => (
                      <option key={user.id} value={user.id}>{user.prenom} {user.nom}</option>
                    ))}
                  </select>
                </div>
              </Modal>
              <div className="mt-6 border overflow-hidden border-b border-gray-200 sm:rounded-lg">
                {filteredSortedProjects.length === 0 ? (
                  <div className="text-center p-4">
                    {user.role === 'ADMIN' ? (
                      <p className="text-lg">Cet utilisateur n'a aucun projet disponible.</p>
                    ) : (
                      <>
                        <p className="text-lg">Aucun projet n'est disponible.</p>
                        <p className="text-sm text-gray-500">Cliquez sur le bouton "+" ci-dessus pour ajouter un nouveau projet.</p>
                      </>
                    )}
                  </div>
                ) : (
                  <div className="table-responsive" style={{ overflowX: 'auto' }}>
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead style={{ backgroundColor: '#E5E7EB' }}>
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-s font-semibold text-black uppercase tracking-wider cursor-pointer"
                          onClick={() => requestSort('NomDuProjet')}
                        >
                          <span className="flex justify-between">
                            Projet
                            {getSortIcon('NomDuProjet', sortConfig)}
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-s font-semibold text-black uppercase tracking-wider cursor-pointer"
                          onClick={() => requestSort('Titre')}
                        >
                          <span className="flex justify-between">
                            Libellé
                            {getSortIcon('Titre', sortConfig)}
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-s font-semibold text-black uppercase tracking-wider cursor-pointer"
                          onClick={() => requestSort('Commentaire')}
                        >
                          <span className="flex justify-between">
                            Commentaire
                            {getSortIcon('Commentaire', sortConfig)}
                          </span>
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-s font-semibold text-black uppercase tracking-wider">
                          Propriétaire
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-s font-semibold text-black uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {filteredSortedProjects.map((project, index) => (
                        <tr
                          key={project.id}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <td className="px-6 py-4">
                            <Link
                              to={`/project-details/${project.id}`}
                              className="text-black hover:text-blue-900 font-bold underline-link"
                            >
                              {project.NomDuProjet}
                            </Link>
                          </td>
                          <td className="px-6 py-4">{project.Titre}</td>
                          <td className="px-6 py-4">{project.Commentaire}</td>
                          <td className="px-6 py-4">
                            {project.prenomProprietaire} {project.nomProprietaire}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                            <button
                              onClick={() => handleEditClick(project)}
                              className={`py-1 px-2 rounded ${
                                hoveredColumn === index ? 'bouton' : 'bouton_cacher'
                              }`}
                            >
                              Modifier
                            </button>
                            {(user.role === 'ADMIN' || project.ID_Utilisateur === user.id) && (
                              <button
                                onClick={() => confirmDelete(project.id, project.NomDuProjet)}
                                className={`py-1 px-2 rounded ml-4 ${
                                  hoveredColumn === index ? 'bouton' : 'bouton_cacher'
                                }`}
                              >
                                Supprimer
                              </button>
                            )}
                            {user.role === 'ADMIN' && selectedUserId && project.ID_Utilisateur !== parseInt(selectedUserId) && (
                              <button
                                onClick={() => confirmUnassign(project.id, project.NomDuProjet, selectedUserId)}
                                className={`py-1 px-2 rounded ml-4 ${
                                  hoveredColumn === index ? 'bouton' : 'bouton_cacher'
                                }`}
                              >
                                Dissocier
                              </button>
                            )}
                            {user.role !== 'ADMIN' && project.ID_Utilisateur !== user.id && (
                              <button
                                onClick={() => confirmUnassign(project.id, project.NomDuProjet, user.id)}
                                className={`py-1 px-2 rounded ml-4 ${
                                  hoveredColumn === index ? 'bouton' : 'bouton_cacher'
                                }`}
                              >
                                Dissocier
                              </button>
                            )}
                            {user.role === 'ADMIN' && (
                              <button
                                onClick={() => openAssignModal(project.id)}
                                className={`py-1 px-2 rounded ml-4 ${
                                  hoveredColumn === index ? 'bouton' : 'bouton_cacher'
                                }`}
                              >
                                Assigner
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ViewProjects);
