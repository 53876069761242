import React, { useState } from 'react';
import axios from 'axios';
//import { useNavigate } from 'react-router-dom';
import { useProjects } from '../ProjectContext';
import '../css/bouton.css';
import '../css/formulaire.css';
import '../css/tableau.css';
import config from '../config/config';
import GrafanaConfigForm from './GrafanaConfigForm';

const CreateProject = ({ onClose, onProjectAdd }) => {
  const [formData, setFormData] = useState({
    NomDuProjet: '',
    Titre: '',
    Commentaire: '',
    orgIdGrafana: '',
    urlGrafana: '',
    grafanaUsername: '',
    grafanaPassword: ''
  });
  const [message, setMessage] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [formSuccess, setFormSuccess] = useState({});

  const [isUrlVerified, setIsUrlVerified] = useState(false);
  const apiBaseUrl = config.FRONTEND.API_URL; 

  //const navigate = useNavigate();
  const { addProject } = useProjects();

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedFormData = { ...formData, [name]: value };
    if (name === 'urlGrafana') {
      try {
        const urlObj = new URL(value);
        // Extraire orgId des paramètres de requête
        const orgIdParam = urlObj.searchParams.get('orgId');
        if (orgIdParam) {
           // Mettre à jour orgIdGrafana avec la valeur extraite
          updatedFormData.orgIdGrafana = orgIdParam;
          // Retirer orgId des paramètres de l'URL
          urlObj.searchParams.delete('orgId');
          // Mettre à jour urlGrafana sans le paramètre orgId
          updatedFormData.urlGrafana = urlObj.origin + urlObj.pathname;
        }
      } catch (error) {
        setMessage("L'URL Grafana n'est pas valide.");
      }
    }
  
    // Réinitialiser isUrlVerified à false si un champ Grafana est modifié
    if (['urlGrafana', 'orgIdGrafana', 'grafanaUsername', 'grafanaPassword'].includes(name)) {
      setIsUrlVerified(false);
    }
  
    // Mettre à jour le formulaire et réinitialiser les messages d'erreur et de succès
    setFormData(updatedFormData);
    setFormErrors({ ...formErrors, [name]: '' });
    setFormSuccess({ ...formSuccess, [name]: '' });
    setMessage('');
  };
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    let errors = {};
    if (!formData.NomDuProjet.trim()) {
      errors.NomDuProjet = 'Le champ Nom du Projet est requis.';
    }
    if (!formData.Titre.trim()) {
      errors.Titre = 'Le champ Libellé est requis.';
    }

    // Validation des champs Grafana
    const grafanaFields = [formData.urlGrafana, formData.orgIdGrafana, formData.grafanaUsername, formData.grafanaPassword];
    const grafanaFieldsFilled = grafanaFields.filter(field => field.trim() !== '');

    const allGrafanaFieldsFilled = grafanaFieldsFilled.length === 4;
    const someGrafanaFieldsFilled = grafanaFieldsFilled.length > 0 && grafanaFieldsFilled.length < 4;

    // Si certains champs sont remplis mais pas tous → erreur
    if (someGrafanaFieldsFilled) {
      errors.Grafana = 'Veuillez remplir les quatre champs Grafana ou les laisser tous vides.';
    }

    if (allGrafanaFieldsFilled && !isUrlVerified) {
      // On vide manuellement la config Grafana
      formData.urlGrafana = '';
      formData.orgIdGrafana = '';
      formData.grafanaUsername = '';
      formData.grafanaPassword = '';
    
      // Transmettre le message à ViewProjects
      if (onProjectAdd) {
        onProjectAdd("⚠️ La configuration Grafana n'est pas valide. Le projet a été créé sans cette configuration.");
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    } else {
      setFormErrors({});
    }

    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      // Si la config Grafana n’est pas vérifiée, on nettoie les champs Grafana
      if (!isUrlVerified) {
        formData.orgIdGrafana = '';
        formData.urlGrafana = '';
        formData.grafanaUsername = '';
        formData.grafanaPassword = '';
      }
      const response = await axios.post(`${apiBaseUrl}/projets`, formData, config);
      const newProject = response.data;

      setMessage('Projet créé avec succès.');
      setFormData({
        NomDuProjet: '',
        Titre: '',
        Commentaire: '',
        orgIdGrafana: '',
        urlGrafana: '',
        grafanaUsername: '',
        grafanaPassword: ''
      });
      addProject(newProject);
      if (onProjectAdd && isUrlVerified) {
        onProjectAdd(); // pas de message si config bonne
      } else if (onProjectAdd) {
        onProjectAdd("⚠️ La configuration Grafana n'est pas valide. Le projet a été créé sans cette configuration.");
      }
      onClose(); // Fermer le modal proprement
    } catch (error) {
      setMessage('Erreur lors de la création du projet. Veuillez réessayer.');
    }
  };

  return (
    <div className="max-w-md mx-auto my-2 bg-white rounded max-w-2xl">
      {message && (
        <div className={message.includes('Erreur') ? "bg-red-500 text-white p-2 rounded" : "bg-green-500 text-white p-2 rounded"}>
          {message}
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="form-group">
          <label className="block text-sm font-medium text-gray-700 form-label">
            Nom du Projet <span className="required" title="Ce champ est obligatoire">*</span>
          </label>
          {formErrors.NomDuProjet && (
            <p className="form-error">{formErrors.NomDuProjet}</p>
          )}
          {formSuccess.NomDuProjet && (
            <p className="form-success text-green-600">{formSuccess.NomDuProjet}</p>
          )}
        </div>
        <input
          type="text"
          name="NomDuProjet"
          value={formData.NomDuProjet}
          onChange={handleChange}
          placeholder="Entrez le nom du projet"
          required
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
        />

        <div className="form-group">
          <label className="block text-sm font-medium text-gray-700 form-label">
            Libellé <span className="required" title="Ce champ est obligatoire">*</span>
          </label>
          {formErrors.Titre && (
            <p className="form-error">{formErrors.Titre}</p>
          )}
        </div>
        <input
          type="text"
          name="Titre"
          value={formData.Titre}
          onChange={handleChange}
          placeholder="Entrez un titre pour le projet"
          required
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
        />

        <div className="form-group">
          <label className="block text-sm font-medium text-gray-700">Commentaire</label>
          {formErrors.Commentaire && (
            <p className="form-error">{formErrors.Commentaire}</p>
          )}
        </div>
        <textarea
          name="Commentaire"
          value={formData.Commentaire}
          onChange={handleChange}
          placeholder="Ajoutez un commentaire (facultatif)"
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
        ></textarea>

        {/* Intégration du composant GrafanaConfigForm */}
        <GrafanaConfigForm
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
          setMessage={setMessage}
          setFormSuccess={setFormSuccess}
          formSuccess={formSuccess}
          isUrlVerified={isUrlVerified}
          setIsUrlVerified={setIsUrlVerified}
        />



        {/* Affichage du message d'erreur pour les champs Grafana */}
        {formErrors.Grafana && (
          <p className="text-red-500 text-sm mb-4">{formErrors.Grafana}</p>
        )}

        <div className="flex justify-between">
          <button type="submit" className="bouton_vert">Ajouter un projet</button>
        </div>
      </form>
    </div>
  );
};

export default CreateProject;
