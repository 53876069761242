import React, { createContext, useState, useContext, useCallback } from 'react';
import axios from 'axios';
import config from './config/config';

const CampaignContext = createContext();

export const useCampaigns = () => useContext(CampaignContext);

export const CampaignProvider = ({ children }) => {
  const [campaigns, setCampaigns] = useState({});

  const getApiBaseUrl = useCallback(() => config.FRONTEND.API_URL, []);


  const fetchCampaigns = useCallback(async (projectId) => {
    const token = localStorage.getItem('token');
    const apiBaseUrl = getApiBaseUrl();
    const config = { headers: { Authorization: `Bearer ${token}` } };
    try {
      const response = await axios.get(`${apiBaseUrl}/projets/${projectId}/details`, config);
      setCampaigns(prev => ({ ...prev, [projectId]: response.data }));
    } catch (error) {
      console.error('Erreur lors de la récupération des campagnes. Veuillez réessayer.');
    }
  }, [getApiBaseUrl]);

  const addCampaign = useCallback((projectId, newCampaign) => {
    setCampaigns(prev => ({
      ...prev,
      [projectId]: [...(prev[projectId] || []), newCampaign]
    }));
  }, []);

  return (
    <CampaignContext.Provider value={{ campaigns, addCampaign, fetchCampaigns }}>
      {children}
    </CampaignContext.Provider>
  );
};
