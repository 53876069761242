// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-label {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.form-label .required {
  color: red;
  margin-left: 4px; /* Ajoute un espace entre le texte du label et l'astérisque */
}

.form-label .optional {
  color: gray;
  margin-left: 4px; /* Ajoute un espace entre le texte du label et "(facultatif)" */
}

.form-group {
  display: flex;
  align-items: center;
  gap: 8px; /* Espace entre le label et le message d'erreur */
}

.form-error {
  color: red;
  font-size: 0.875rem; /* Taille du texte pour les messages d'erreur */
  margin-left: 8px; /* Espace entre l'astérisque et le message d'erreur */
}
`, "",{"version":3,"sources":["webpack://./src/css/formulaire.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,gBAAgB,EAAE,6DAA6D;AACjF;;AAEA;EACE,WAAW;EACX,gBAAgB,EAAE,+DAA+D;AACnF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ,EAAE,iDAAiD;AAC7D;;AAEA;EACE,UAAU;EACV,mBAAmB,EAAE,+CAA+C;EACpE,gBAAgB,EAAE,qDAAqD;AACzE","sourcesContent":[".form-label {\n  display: inline-flex;\n  align-items: center;\n  position: relative;\n}\n\n.form-label .required {\n  color: red;\n  margin-left: 4px; /* Ajoute un espace entre le texte du label et l'astérisque */\n}\n\n.form-label .optional {\n  color: gray;\n  margin-left: 4px; /* Ajoute un espace entre le texte du label et \"(facultatif)\" */\n}\n\n.form-group {\n  display: flex;\n  align-items: center;\n  gap: 8px; /* Espace entre le label et le message d'erreur */\n}\n\n.form-error {\n  color: red;\n  font-size: 0.875rem; /* Taille du texte pour les messages d'erreur */\n  margin-left: 8px; /* Espace entre l'astérisque et le message d'erreur */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
