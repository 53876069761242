// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-hover-underline {
    color: black; /* Couleur du texte */
    position: relative;
  }
  
  .custom-hover-underline::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px; /* Épaisseur de la ligne */
    background-color: #166534; /* Couleur verte */
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease;
  }
  
  .custom-hover-underline:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  `, "",{"version":3,"sources":["webpack://./src/css/hoverunderline.css"],"names":[],"mappings":"AAAA;IACI,YAAY,EAAE,qBAAqB;IACnC,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,WAAW;IACX,WAAW,EAAE,0BAA0B;IACvC,yBAAyB,EAAE,kBAAkB;IAC7C,oBAAoB;IACpB,8BAA8B;IAC9B,+BAA+B;EACjC;;EAEA;IACE,oBAAoB;IACpB,6BAA6B;EAC/B","sourcesContent":[".custom-hover-underline {\n    color: black; /* Couleur du texte */\n    position: relative;\n  }\n  \n  .custom-hover-underline::after {\n    content: \"\";\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    width: 100%;\n    height: 2px; /* Épaisseur de la ligne */\n    background-color: #166534; /* Couleur verte */\n    transform: scaleX(0);\n    transform-origin: bottom right;\n    transition: transform 0.3s ease;\n  }\n  \n  .custom-hover-underline:hover::after {\n    transform: scaleX(1);\n    transform-origin: bottom left;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
