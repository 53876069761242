import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../css/bouton.css';
import '../css/tableau.css';
import config from '../config/config';
import { useCampaigns } from '../CampaignContext';

const EditDashboard = ({ dashboard, onEditSuccess, onClose }) => {
  const { id, detailId } = useParams(); // ID du projet et ID du détail
  const [dashboardData, setDashboardData] = useState({ ...dashboard });
  const [DateDeDebut, setDateDeDebut] = useState('');
  const [DateDeFin, setDateDeFin] = useState('');
  const [excludeTimeRange, setExcludeTimeRange] = useState(false);
  const [message, setMessage] = useState('');
  const apiBaseUrl = config.FRONTEND.API_URL; 
  const { campaigns } = useCampaigns();


  useEffect(() => {
    if (campaigns[id]) {
        const currentCampaign = campaigns[id].find(
            (campaign) => campaign.id === parseInt(detailId)
        );

        if (currentCampaign) {

            const formattedStartDate = parseBackendDate(currentCampaign.DateDeDebut);
            const formattedEndDate = parseBackendDate(currentCampaign.DateDeFin);

            if (formattedStartDate && formattedEndDate) {
                setDateDeDebut(formattedStartDate);
                setDateDeFin(formattedEndDate);
            } else {
                console.error("⚠️ Dates invalides après formatage :", {
                    DateDeDebut: currentCampaign.DateDeDebut,
                    DateDeFin: currentCampaign.DateDeFin,
                });
            }
        } else {
            console.error("❌ Aucune campagne correspondante trouvée.");
        }
    } else {
        console.error("❌ Aucune donnée trouvée pour l'ID de projet :", id);
    }
}, [campaigns, id, detailId]);



  // Fonction pour convertir une date du backend en format ISO
  const parseBackendDate = (date) => {
    if (!date) return ''; // Si la date est vide, retourner une chaîne vide

    // Si la date est déjà au format ISO (YYYY-MM-DDTHH:MM), on la retourne directement
    if (date.includes('T') && !isNaN(Date.parse(date))) {
        return date;
    }

    // Vérifie si la date est au format "DD/MM/YYYY HH:mm"
    const regex = /(\d{2})\/(\d{2})\/(\d{4})\s(\d{2}:\d{2})/;
    const match = date.match(regex);

    if (!match) return '';

    const [, day, month, year, time] = match;
    return `${year}-${month}-${day}T${time}`; // Conversion au format attendu par datetime-local
};

const updateDashboardUrl = (excludeTime) => {
  let updatedUrl = dashboardData.url_Dashboard.replace(/([?&]from=\d+)|([?&]to=\d+)|([?&]orgId=\d+)/g, '');

  if (excludeTime) {
      updatedUrl += `${updatedUrl.includes('?') ? '&' : '?'}orgId=10`; // Remplace 10 par la vraie orgId
  } else {
      const fromTime = new Date(DateDeDebut).getTime();
      const toTime = new Date(DateDeFin).getTime();
      updatedUrl += `${updatedUrl.includes('?') ? '&' : '?'}from=${fromTime}&to=${toTime}&orgId=10`;
  }

  setDashboardData((prevData) => ({ ...prevData, url_Dashboard: updatedUrl }));
};


  const handleChange = (e) => {
    setDashboardData({ ...dashboardData, [e.target.name]: e.target.value });
    setMessage('');
  };

  const handleDateChange = (e, setDate) => {
    const { name, value } = e.target;
  
    if (name === "DateDeDebut" && DateDeFin && value > DateDeFin) {
      setMessage("La date de début ne peut pas être postérieure à la date de fin.");
      return;
    }
  
    if (name === "DateDeFin" && DateDeDebut && value < DateDeDebut) {
      setMessage("La date de fin ne peut pas être antérieure à la date de début.");
      return;
    }
  
    setMessage('');
    setDate(value);
  };
  

  const handleExcludeTimeRangeChange = (e) => {
    const newExcludeTime = e.target.checked;
    setExcludeTimeRange(newExcludeTime);
    updateDashboardUrl(newExcludeTime);
};


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      const updatedDashboardData = {
        ...dashboardData,
        DateDeDebut,
        DateDeFin
      };

      await axios.put(
        `${apiBaseUrl}/projets/${id}/details/${detailId}/dashboards/${dashboard.id}`,
        updatedDashboardData,
        config
      );

      setMessage('Dashboard modifié avec succès.');
      if (onEditSuccess) {
        onEditSuccess();
      }
      onClose();
    } catch (error) {
      console.error('Erreur lors de la modification du dashboard:', error);
      setMessage('Erreur lors de la modification. Veuillez réessayer.');
    }
  };

  // Mapping des types de dashboard
  const dashboardTypeLabels = {
    SyntheticMonitoring: 'Synthetic Monitoring',
    Infrastructure: 'Infrastructure',
    InfraEndpoint: 'Infrastructure Endpoint',
    EndpointAppPerf: 'Endpoint Application Monitoring',
    DigitalJourney: 'Digital Journey',
    DigitalExpMon: 'Digital Experience Monitoring',
    LoadTest: 'Load Test',
    MeteoServices: 'Météo des Services',
    Autre: 'Autre'
  };

  return (
    <div className="max-w-md mx-auto my-2 p-2 bg-white rounded max-w-2xl">
      <h2 className="text-lg font-semibold text-gray-800 mb-4">Modifier le Dashboard</h2>
      {message && (
        <div className={message.includes('Erreur') ? "bg-red-500 text-white p-2 rounded" : "bg-red-500 text-white p-2 rounded"}>
          {message}
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        <p className="text-sm text-gray-500 text-right"><span className="text-red-500">*</span> : Champs obligatoires</p>

        {/* Option pour exclure la timeRange */}
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            checked={excludeTimeRange}
            onChange={handleExcludeTimeRangeChange}
            className="mr-2"
          />
          <label className="text-red-500">Ne pas ajouter la timeRange</label>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Date de Début <span className="text-red-500">*</span></label>
          <input
            type="datetime-local"
            name="DateDeDebut"
            value={DateDeDebut || ''}
            onChange={(e) => handleDateChange(e, setDateDeDebut)}
            required
            max={DateDeFin || undefined} // Empêche de dépasser la date de fin
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm custom-focus"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Date de Fin <span className="text-red-500">*</span></label>
          <input
            type="datetime-local"
            name="DateDeFin"
            value={DateDeFin || ''}
            onChange={(e) => handleDateChange(e, setDateDeFin)}
            required
            min={DateDeDebut || undefined} // Empêche d’aller avant la date de début
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm custom-focus"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Nom <span className="text-red-500">*</span></label>
          <input 
            type="text" 
            name="nom" 
            value={dashboardData.nom} 
            onChange={handleChange} 
            placeholder="Entrez le nom du dashboard"
            required 
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">URL du Dashboard <span className="text-red-500">*</span></label>
          <input 
            type="url" 
            name="url_Dashboard" 
            value={dashboardData.url_Dashboard} 
            onChange={handleChange} 
            placeholder="Entrez l'URL du dashboard"
            required 
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Type de Dashboard <span className="text-red-500">*</span></label>
          <select 
            name="TypeDashboard" 
            value={dashboardData.TypeDashboard} 
            onChange={handleChange} 
            required 
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
          >
            {Object.keys(dashboardTypeLabels).map((key) => (
              <option key={key} value={key}>{dashboardTypeLabels[key]}</option>
            ))}
          </select>
        </div>

        <div className="flex justify-between">
          <button type="submit" className="bouton_vert">Modifier</button>
        </div>
      </form>
    </div>
  );
};

export default EditDashboard;
