import React from 'react';

const EditProject = ({ project, handleChange, formErrors, formSuccess }) => {
  return (
    <div>
      <div className="py-3 border-b border-gray-300 flex items-center justify-between mb-4">
          <h2 className="text-xl font-semibold mb-4">Informations Générales</h2>
          <p className="text-sm text-gray-500">
            <span className="text-red-500">*</span> : Champs obligatoires
          </p>
      </div>
      <div className="mb-4">
        <label>Nom du Projet <span className="text-red-500">*</span></label>
        {formErrors.NomDuProjet && (
          <p className="form-error text-red-500">{formErrors.NomDuProjet}</p>
        )}
        {formSuccess.NomDuProjet && (
          <p className="form-success text-green-600">{formSuccess.NomDuProjet}</p>
        )}
        <input
          type="text"
          value={project.NomDuProjet}
          onChange={(e) => handleChange('NomDuProjet', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight custom-focus"
        />
      </div>
      <div className="mb-4">
        <label>Titre <span className="text-red-500">*</span></label>
        <input
          type="text"
          value={project.Titre}
          onChange={(e) => handleChange('Titre', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight custom-focus"
        />
      </div>
      <div className="mb-4">
        <label>Commentaire (Optionnel)</label>
        <textarea
          value={project.Commentaire}
          onChange={(e) => handleChange('Commentaire', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight custom-focus"
        ></textarea>
      </div>
    </div>
  );
};

export default EditProject;
