import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../css/bouton.css';
import '../css/tableau.css';
import { useSortedData, useSortConfig, getSortIcon } from './SortUtils';
import moment from 'moment';

const ProjectDetailsTableau = ({
  details,
  projectId,
  searchQuery,
  setSearchQuery,
  fetchProjectDetails,
  toggleAddDetailModal,
  toggleDocumentsModal,
  handleDeleteDetail,
  handleMouseEnter,
  handleMouseLeave,
  hoveredColumn,
  formatDate
}) => {
  const [editDetailId, setEditDetailId] = useState(null);
  const [editFormData, setEditFormData] = useState({
    TitreCampagne: '',
    Description: '',
    DateDeDebut: '',
    DateDeFin: ''
  });

  const { sortConfig, requestSort } = useSortConfig();
  const navigate = useNavigate();

  const filterDetails = () => {
    return details.filter((detail) => {
      const formattedDateDeDebut = moment(detail.DateDeDebut, 'DD-MM-YYYYTHH:mm', true).format('DD-MM-YYYY HH:mm');
      const formattedDateDeFin = moment(detail.DateDeFin, 'DD-MM-YYYYTHH:mm', true).format('DD-MM-YYYY HH:mm');

      return (
        (detail.TitreCampagne && detail.TitreCampagne.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (detail.Description && detail.Description.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (formattedDateDeDebut.includes(searchQuery)) ||
        (formattedDateDeFin.includes(searchQuery))
      );
    });
  };

  const filteredSortedDetails = useSortedData(filterDetails(), sortConfig);

  const handleEditClick = (detail) => {
    setEditDetailId(detail.id);
    setEditFormData({
      TitreCampagne: decodeURIComponent(detail.TitreCampagne),
      Description: decodeURIComponent(detail.Description),
      DateDeDebut: detail.DateDeDebut ? moment(detail.DateDeDebut, 'DD-MM-YYYYTHH:mm').format('YYYY-MM-DDTHH:mm') : '',
      DateDeFin: detail.DateDeFin ? moment(detail.DateDeFin, 'DD-MM-YYYYTHH:mm').format('YYYY-MM-DDTHH:mm') : ''
    });
  };

  const handleCancelClick = () => {
    setEditDetailId(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({
      ...editFormData,
      [name]: value
    });
  };

  const handleDateChange = (name, value) => {
    const formattedDate = moment(value, 'YYYY-MM-DDTHH:mm').utc().toISOString(); // Format ISO en UTC
    setEditFormData({
      ...editFormData,
      [name]: formattedDate  // Stocker sous forme ISO UTC
    });
  };

  const handleSaveClick = async () => {
    try {
      const token = localStorage.getItem('token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';

      // Formater les dates dans le format attendu par le backend
      const formattedEditFormData = {
        ...editFormData,
        DateDeDebut: moment(editFormData.DateDeDebut, 'YYYY-MM-DDTHH:mm').format('DD-MM-YYYYTHH:mm'),
        DateDeFin: moment(editFormData.DateDeFin, 'YYYY-MM-DDTHH:mm').format('DD-MM-YYYYTHH:mm'),
      };

      // Envoi de la requête au backend
      await axios.put(`${apiBaseUrl}/projets/${projectId}/details/${editDetailId}`, formattedEditFormData, config);

      // Recharger les données pour vérifier la mise à jour
      await fetchProjectDetails();
      setEditDetailId(null);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du détail:', error.response ? error.response.data : error);
    }
  };

  return (
    <div>
      <h1 className="text-lg font-bold my-2">Campagnes</h1>
      <div className="flex justify-between items-center mb-4">
        <input
          className="search-bar border p-2 rounded mr-4 custom-focus"
          type="text"
          placeholder="Rechercher par Titre de Campagne, Description ou Date"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={toggleAddDetailModal} className="text-white font-bold py-1 px-2 rounded text-2xl bg-green-800 hover:bg-green-700">
          <i className="fas fa-plus"></i>
        </button>
      </div>

      <div className="mt-6 border overflow-hidden border-b border-gray-200 sm:rounded-lg">
        {filteredSortedDetails.length === 0 ? (
          <div className="text-center p-4">
            <p className="text-lg">Aucun détail n'est disponible</p>
            <p className="text-sm text-gray-500">Cliquez sur le bouton "+" ci-dessus pour ajouter une nouvelle campagne.</p>
          </div>
        ) : (
          <div className="table-responsive" style={{ overflowX: 'auto' }}>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider cursor-pointer"
                    style={{ width: '25%' }}
                    onClick={() => requestSort('TitreCampagne')}
                  >
                    <span className="flex justify-between">
                      Titre de la Campagne
                      {getSortIcon('TitreCampagne', sortConfig)}
                    </span>
                  </th>
                  <th
                    className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider cursor-pointer"
                    style={{ width: '25%' }}
                    onClick={() => requestSort('DateDeDebut')}
                  >
                    <span className="flex justify-between">
                      Date de Début
                      {getSortIcon('DateDeDebut', sortConfig)}
                    </span>
                  </th>
                  <th
                    className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider cursor-pointer"
                    style={{ width: '25%' }}
                    onClick={() => requestSort('DateDeFin')}
                  >
                    <span className="flex justify-between">
                      Date de Fin
                      {getSortIcon('DateDeFin', sortConfig)}
                    </span>
                  </th>
                  <th
                    className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider cursor-pointer"
                    style={{ width: '25%' }}
                    onClick={() => requestSort('Description')}
                  >
                    <span className="flex justify-between">
                      Description
                      {getSortIcon('Description', sortConfig)}
                    </span>
                  </th>
                  <th
                    className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider"
                    style={{ width: '25%' }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredSortedDetails.map((detail, index) => (
                  <tr
                    key={detail.id}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {editDetailId === detail.id ? (
                      <>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm">
                          <input
                            type="text"
                            name="TitreCampagne"
                            value={editFormData.TitreCampagne}
                            onChange={handleInputChange}
                            className="bouton_edit custom-focus"
                          />
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm">
                          <input
                            type="datetime-local"
                            name="DateDeDebut"
                            value={editFormData.DateDeDebut ? moment(editFormData.DateDeDebut).format('YYYY-MM-DDTHH:mm') : ''}
                            onChange={(e) => handleDateChange('DateDeDebut', e.target.value)}
                            className="bouton_edit custom-focus"
                          />
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm">
                          <input
                            type="datetime-local"
                            name="DateDeFin"
                            value={editFormData.DateDeFin ? moment(editFormData.DateDeFin).format('YYYY-MM-DDTHH:mm') : ''}
                            onChange={(e) => handleDateChange('DateDeFin', e.target.value)}
                            className="bouton_edit custom-focus"
                          />
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm">
                          <input
                            type="text"
                            name="Description"
                            value={editFormData.Description}
                            onChange={handleInputChange}
                            className="bouton_edit custom-focus"
                          />
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm space-x-2">
                          <button onClick={handleSaveClick} className="bouton">
                            Enregistrer
                          </button>
                          <button onClick={handleCancelClick} className="bouton">
                            Annuler
                          </button>
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm">
                          {decodeURIComponent(detail.TitreCampagne)}
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm">
                          {formatDate(detail.DateDeDebut)}
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm">
                          {formatDate(detail.DateDeFin)}
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm">
                          {decodeURIComponent(detail.Description)}
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="flex space-x-2 overflow-x-auto">
                            <button
                              onClick={() => navigate(`/projets/${projectId}/details/${detail.id}/dashboards`)}
                              className={`py-1 px-2 rounded ${hoveredColumn === index ? 'bouton' : 'bouton_cacher'}`}
                            >
                              Dashboards
                            </button>
                            <button
                              onClick={() => toggleDocumentsModal(detail.id)}
                              className={`py-1 px-2 rounded ml-4 ${hoveredColumn === index ? 'bouton' : 'bouton_cacher'}`}
                            >
                              Documents
                            </button>
                            <button
                              onClick={() => handleEditClick(detail)}
                              className={`py-1 px-2 rounded ml-4 ${hoveredColumn === index ? 'bouton' : 'bouton_cacher'}`}
                            >
                              Modifier
                            </button>
                            <button
                              onClick={() => handleDeleteDetail(detail.id)}
                              className={`py-1 px-2 rounded ml-4 ${hoveredColumn === index ? 'bouton' : 'bouton_cacher'}`}
                            >
                              Supprimer
                            </button>
                          </div>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectDetailsTableau;
