import React, { useState , useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../css/bouton.css';
import '../css/tableau.css';
import { useSortedData, useSortConfig, getSortIcon } from './SortUtils';
import config from '../config/config';

const ProjectDetailsTableau = ({
  details,
  projectId,
  searchQuery,
  setSearchQuery,
  fetchProjectDetails,
  toggleAddDetailModal,
  toggleDocumentsModal,
  handleDeleteDetail,
  handleMouseEnter,
  handleMouseLeave,
  hoveredColumn,
  setDetails
}) => {
  const [editDetailId, setEditDetailId] = useState(null);
  const [editFormData, setEditFormData] = useState({
    TitreCampagne: '',
    Description: '',
    DateDeDebut: '',
    DateDeFin: ''
  });

  const { sortConfig, requestSort } = useSortConfig();
  const navigate = useNavigate();
  const apiBaseUrl = config.FRONTEND.API_URL; 

  useEffect(() => {
    // console.log("Détails reçus :", details);
  }, [details]);
  

  const filterDetails = () => {
    return details.filter((detail) => {

      return (
        (detail.TitreCampagne && detail.TitreCampagne.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (detail.Description && detail.Description.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (detail.DateDeDebut.includes(searchQuery)) ||
        (detail.DateDeFin.includes(searchQuery))
      );      
      
    });
  };

  const filteredSortedDetails = useSortedData(filterDetails(), sortConfig);

  const handleEditClick = (detail) => {
    // console.log("Édition activée pour :", detail);
    setEditDetailId(detail.id);
    setEditFormData({
      TitreCampagne: decodeURIComponent(detail.TitreCampagne),
      Description: decodeURIComponent(detail.Description),
      DateDeDebut: detail.DateDeDebut || '',
      DateDeFin: detail.DateDeFin || '',
    });
  
  };

  const handleCancelClick = () => {
    // console.log("Édition annulée");
    setEditDetailId(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // console.log(`Changement dans ${name} :`, value);
    setEditFormData({
      ...editFormData,
      [name]: value
    });
  };

  const handleDateChange = (name, value) => {
    // console.log(`Date modifiée pour ${name} :`, value);
    const formattedDate = value; // Utilise directement la valeur saisie (format ISO)
    // console.log(`Date formatée pour le backend (${name}) :`, formattedDate);
  
    // Mise à jour du formulaire d'édition
    setEditFormData({
      ...editFormData,
      [name]: formattedDate,
    });
  
    // Mise à jour immédiate de l'état du tableau
    setDetails((prevDetails) =>
      prevDetails.map((detail) =>
        detail.id === editDetailId
          ? { ...detail, [name]: formattedDate }
          : detail
      )
    );
  };
  

  const parseBackendDate = (date) => {
    if (!date) return ''; // Si la date est vide ou nulle, retournez une chaîne vide
  
    // Si la date est déjà au format ISO (elle contient 'T'), retournez-la directement
    if (date.includes('T')) {
      return date;
    }
  
    // Vérifiez si la date correspond au format attendu 'DD/MM/YYYY HH:mm'
    const regex = /(\d{2})\/(\d{2})\/(\d{4})\s(\d{2}:\d{2})/;
    const match = date.match(regex);
  
    if (!match) {
      console.error(`Format de date invalide : ${date}`);
      return ''; // Retournez une chaîne vide si le format ne correspond pas
    }
  
    const [day, month, year, time] = match.slice(1);
    return `${year}-${month}-${day}T${time}`;
  };
  

  const toISOFormat = (date) => {
    if (!date) return null; // Gère les cas où la date est vide
    // Si la date est déjà au format ISO, la retourner directement
    if (date.includes('T')) {
      return date;
    }
    // Gérer les formats 'DD/MM/YYYY HH:mm'
    const regex = /(\d{2})\/(\d{2})\/(\d{4})\s(\d{2}:\d{2})/;
    const match = date.match(regex);
    if (!match) {
      console.error(`Format de date invalide : ${date}`);
      return null; // Retourne null si le format ne correspond pas
    }
    const [day, month, year, time] = match.slice(1);
    return `${year}-${month}-${day}T${time}`;
  };
  

  const handleSaveClick = async () => {
    // console.log("Données envoyées pour sauvegarde :", editFormData);
    try {
      const token = localStorage.getItem('token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
  
      // Reformater les dates en ISO avant de les envoyer au backend
      const formattedEditFormData = {
        ...editFormData,
        DateDeDebut: toISOFormat(editFormData.DateDeDebut), // Conversion en ISO si nécessaire
        DateDeFin: toISOFormat(editFormData.DateDeFin),     // Conversion en ISO si nécessaire
      };
  
      // console.log("Données formatées pour le backend :", formattedEditFormData);
  
      // Envoi de la requête au backend
      await axios.put(`${apiBaseUrl}/projets/${projectId}/details/${editDetailId}`, formattedEditFormData, config);
  
      // Recharger les données pour vérifier la mise à jour
      await fetchProjectDetails();
      setEditDetailId(null);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du détail:', error.response ? error.response.data : error);
    }
  };
  
  return (
    <div>
      <h1 className="text-lg font-bold my-2">Campagnes</h1>
      <div className="flex justify-between items-center mb-4">
        <input
          className="search-bar border p-2 rounded mr-4 custom-focus"
          type="text"
          placeholder="Rechercher par Titre de Campagne, Description ou Date"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={toggleAddDetailModal} className="text-white font-bold py-1 px-2 rounded text-2xl bg-green-800 hover:bg-green-700">
          <i className="fas fa-plus"></i>
        </button>
      </div>

      <div className="mt-6 border overflow-hidden border-b border-gray-200 sm:rounded-lg">
        {filteredSortedDetails.length === 0 ? (
          <div className="text-center p-4">
            <p className="text-lg">Aucun détail n'est disponible</p>
            <p className="text-sm text-gray-500">Cliquez sur le bouton "+" ci-dessus pour ajouter une nouvelle campagne.</p>
          </div>
        ) : (
          <div className="table-responsive" style={{ overflowX: 'auto' }}>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider cursor-pointer"
                    style={{ width: '20%' }}
                    onClick={() => requestSort('TitreCampagne')}
                  >
                    <span className="flex justify-between">
                      Titre de la Campagne
                      {getSortIcon('TitreCampagne', sortConfig)}
                    </span>
                  </th>
                  <th
                    className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider cursor-pointer"
                    style={{ width: '20%' }}
                    onClick={() => requestSort('DateDeDebut')}
                  >
                    <span className="flex justify-between">
                      Date de Début
                      {getSortIcon('DateDeDebut', sortConfig)}
                    </span>
                  </th>
                  <th
                    className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider cursor-pointer"
                    style={{ width: '20%' }}
                    onClick={() => requestSort('DateDeFin')}
                  >
                    <span className="flex justify-between">
                      Date de Fin
                      {getSortIcon('DateDeFin', sortConfig)}
                    </span>
                  </th>
                  <th
                    className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider cursor-pointer"
                    style={{ width: '20%' }}
                    onClick={() => requestSort('Description')}
                  >
                    <span className="flex justify-between">
                      Description
                      {getSortIcon('Description', sortConfig)}
                    </span>
                  </th>
                  <th
                    className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider"
                    style={{ width: '20%' }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredSortedDetails.map((detail, index) => (
                  <tr
                    key={detail.id}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {/* {console.log("Détail affiché dans le tableau :", detail)} */}
                    {editDetailId === detail.id ? (
                      <>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm  tracking-wider" style={{ width: '20%' }}>
                          <input
                            type="text"
                            name="TitreCampagne"
                            value={editFormData.TitreCampagne}
                            onChange={handleInputChange}
                            className="bouton_edit custom-focus"
                          />
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm  tracking-wider" style={{ width: '20%' }}>
                          <input
                            type="datetime-local"
                            name="DateDeDebut"
                            value={parseBackendDate(detail.DateDeDebut)}
                            onChange={(e) => handleDateChange('DateDeDebut', e.target.value)}
                            className="bouton_edit custom-focus"
                          />
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm  tracking-wider" style={{ width: '20%' }}>
                          <input
                            type="datetime-local"
                            name="DateDeFin"
                            value={parseBackendDate(detail.DateDeFin)}
                            onChange={(e) => handleDateChange('DateDeFin', e.target.value)}
                            className="bouton_edit custom-focus"
                          />
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm  tracking-wider" style={{ width: '20%' }}>
                          <input
                            type="text"
                            name="Description"
                            value={editFormData.Description}
                            onChange={handleInputChange}
                            className="bouton_edit custom-focus"
                          />
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm space-x-2  tracking-wider" style={{ width: '2%' }}>
                          <button onClick={handleSaveClick} className="bouton">
                            Enregistrer
                          </button>
                          <button onClick={handleCancelClick} className="bouton">
                            Annuler
                          </button>
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm">
                          {decodeURIComponent(detail.TitreCampagne)}
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm">
                          {detail.DateDeDebut}
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm">
                          {detail.DateDeFin}
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm">
                          {decodeURIComponent(detail.Description)}
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="flex space-x-2 overflow-x-auto">
                            <button
                              onClick={() => navigate(`/projets/${projectId}/details/${detail.id}/dashboards`)}
                              className={`py-1 px-2 rounded ${hoveredColumn === index ? 'bouton' : 'bouton_cacher'}`}
                            >
                              Dashboards
                            </button>
                            <button
                              onClick={() => toggleDocumentsModal(detail.id)}
                              className={`py-1 px-2 rounded ml-4 ${hoveredColumn === index ? 'bouton' : 'bouton_cacher'}`}
                            >
                              Documents
                            </button>
                            <button
                              onClick={() => handleEditClick(detail)}
                              className={`py-1 px-2 rounded ml-4 ${hoveredColumn === index ? 'bouton' : 'bouton_cacher'}`}
                            >
                              Modifier
                            </button>
                            <button
                              onClick={() => handleDeleteDetail(detail.id)}
                              className={`py-1 px-2 rounded ml-4 ${hoveredColumn === index ? 'bouton' : 'bouton_cacher'}`}
                            >
                              Supprimer
                            </button>
                          </div>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectDetailsTableau;
