import React, { useState, useEffect, useCallback } from 'react';
import '../css/bouton.css';
import '../css/formulaire.css';
import { useParams, useNavigate } from 'react-router-dom'; 
import axios from 'axios';
import { useProjects } from '../ProjectContext';
import EditProject from './EditProject';
import EditGrafanaConfig from './EditGrafanaConfig';
import TabsGrafanaConfig from './TabsGrafanaConfig';
import config from '../config/config';


const ProjectConfig = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { updateProjectName } = useProjects();
  const [project, setProject] = useState({
    NomDuProjet: '',
    Titre: '',
    Commentaire: '',
    urlGrafana: '',
    orgIdGrafana: '',
    grafanaUsername: '',
    grafanaPassword: ''
  });
  const [loading, setLoading] = useState(true);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [selectedTab, setSelectedTab] = useState('general');
  const [showPassword, setShowPassword] = useState(false);
  const [showGeneralTabAlert, setShowGeneralTabAlert] = useState(false);
  const [isUrlVerified, setIsUrlVerified] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false); // Suivi des modifications non sauvegardées
  const getApiBaseUrl = useCallback(() => config.FRONTEND.API_URL, []);
  const apiBaseUrl = getApiBaseUrl();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  const handleArrowBackClick = () => {
    if (hasUnsavedChanges) {
      const confirmLeave = window.confirm(
        "Êtes-vous sûr de vouloir quitter cette page ? Cela entraînera la suppression des données non sauvegardées. Veuillez cliquer sur le bouton 'Enregistrer' avant de quitter la page."
      );
      if (!confirmLeave) return;
    }
    navigate(-1);
  };

  // Gestion des messages de succès et d'erreur
  const [formErrors, setFormErrors] = useState({});
  const [formSuccess, setFormSuccess] = useState({});
  const [orgImportSuccess, setOrgImportSuccess] = useState(false);

  // Fonction qui vérifie si le bouton "Enregistrer" doit être désactivé
  const checkSaveDisabled = useCallback(() => {
    const { urlGrafana, orgIdGrafana, grafanaUsername, grafanaPassword } = project;
    const grafanaFields = [urlGrafana, orgIdGrafana, grafanaUsername, grafanaPassword];
    const allFilled = grafanaFields.every(field => String(field).trim() !== '');
    const allEmpty = grafanaFields.every(field => String(field).trim() === '');
    return !(allFilled || allEmpty);
  }, [project]);

  useEffect(() => {
    setIsSaveDisabled(checkSaveDisabled());
  }, [project, checkSaveDisabled]);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  
    if (tab === 'general') {
      setShowGeneralTabAlert(false); // Masquer l'alerte lorsque l'onglet "Informations Générales" est cliqué
    }
  
    if (!orgImportSuccess) {
      setFormErrors({});
      setFormSuccess({});
    }
  };
  
  const handleChange = (field, value) => {
    if (field === 'urlGrafana' || field === 'orgIdGrafana' || field === 'grafanaUsername' || field === 'grafanaPassword') {
      setIsUrlVerified(false); // Réinitialise la vérification de l'URL lorsque l'un des champs Grafana est modifié
    }
  
    if (field === 'urlGrafana') {
      // Détecter si l'URL contient ?orgId= et extraire l'ID de l'organisation
      const orgIdMatch = value.match(/[?&]orgId=(\d+)/);
      let cleanedUrl = value;
  
      if (orgIdMatch) {
        const extractedOrgId = orgIdMatch[1];
        // Retirer ?orgId= ou &orgId= de l'URL
        cleanedUrl = value.replace(/[?&]orgId=\d+/, '');
  
        setProject((prevProject) => ({
          ...prevProject,
          [field]: cleanedUrl,        // Mise à jour de l'URL sans le paramètre orgId
          orgIdGrafana: extractedOrgId // Mise à jour automatique de orgIdGrafana
        }));
        return;
      }
    }
    
    setHasUnsavedChanges(true); // Marque les changements comme non sauvegardés
    setProject((prevProject) => ({
      ...prevProject,
      [field]: value,
    }));
  };

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const token = localStorage.getItem('token');
        const apiBaseUrl = getApiBaseUrl();
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const response = await axios.get(`${apiBaseUrl}/projets/${id}/with-grafana`, config);

        const projectData = {
          id: response.data.id,
          NomDuProjet: response.data.NomDuProjet,
          Titre: response.data.Titre,
          Commentaire: response.data.Commentaire,
          urlGrafana: response.data.urlGrafana,
          orgIdGrafana: response.data.orgIdGrafana,
          grafanaUsername: response.data.grafanaUsername,
          grafanaPassword: response.data.grafanaPassword
        };

        setProject(projectData);
        setLoading(false);
      } catch (error) {
        console.error('Erreur lors de la récupération des données du projet :', error);
        setLoading(false);
      }
    };

    fetchProjectData();
  }, [id , getApiBaseUrl]);

  const saveProjectData = async (updatedProject) => {
    try {
      const token = localStorage.getItem('token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
  
      const { urlGrafana, orgIdGrafana, grafanaUsername, grafanaPassword } = updatedProject;
      const grafanaFields = {
        urlGrafana: urlGrafana ? urlGrafana.trim() : null,
        orgIdGrafana: orgIdGrafana ? parseInt(orgIdGrafana, 10) || null : null,
        grafanaUsername: grafanaUsername ? grafanaUsername.trim() : null,
        grafanaPassword: grafanaPassword ? grafanaPassword.trim() : null
      };
 
      const filledGrafanaFields = Object.values(grafanaFields).filter(field => field !== null);
      if (filledGrafanaFields.length > 0 && filledGrafanaFields.length < 4) {
        setFormErrors({ Grafana: 'Veuillez remplir les quatre champs Grafana ou les laisser tous vides.' });
        return;
      } else {
        setFormErrors({});
      }
  
      const updatedData = {
        ...updatedProject,
        ...grafanaFields
      };
  
      await axios.put(`${apiBaseUrl}/projets/${updatedProject.id}`, updatedData, config);
      await axios.put(`${apiBaseUrl}/projets/${updatedProject.id}/grafana`, updatedData, config);

      // Afficher le message de succès général
      setFormSuccess({ general: 'Modifications enregistrées avec succès !' });
      setFormErrors({});

      // Appeler updateProjectName pour mettre à jour le nom du projet dans le contexte
      updateProjectName(updatedProject.id, updatedProject.NomDuProjet);
      setHasUnsavedChanges(false); // Réinitialise après la sauvegarde
  
    } catch (error) {
      console.error("Erreur lors de la sauvegarde des modifications du projet :", error);
  
      if (error.response && error.response.data.error === "Unauthorized") {
        setFormErrors({
          grafanaUsername: "Identifiant ou mot de passe incorrect.",
          grafanaPassword: "Identifiant ou mot de passe incorrect."
        });
      } else {
        setFormErrors({ NomDuProjet: 'Impossible de mettre à jour le projet' });
      }
      setFormSuccess({});
    }
  };

  const handleImportOrgName = async () => {
    const { urlGrafana, orgIdGrafana, grafanaUsername, grafanaPassword } = project;
  
    try {
      const urlObj = new URL(urlGrafana);
      const baseGrafanaUrl = urlObj.origin + urlObj.pathname;
      const response = await axios.get(`${apiBaseUrl}/proxy/grafana/orgs/${orgIdGrafana}`, {
        params: { baseGrafanaUrl, grafanaUsername, grafanaPassword }
      });
  
      if (response.data.error === "Unauthorized") {
        setFormErrors({
          grafanaUsername: "Identifiant ou mot de passe incorrect.",
          grafanaPassword: "Identifiant ou mot de passe incorrect."
        });
        setFormSuccess({});
        setOrgImportSuccess(false);
      } else {
        const orgName = response.data.name;
        setProject((prevProject) => ({ ...prevProject, NomDuProjet: orgName }));
        setFormSuccess({ NomDuProjet: "Nom de l'organisation importé avec succès." });
        setFormErrors({});
        setOrgImportSuccess(true);
        setShowGeneralTabAlert(true); // Affiche l'alerte sur l'onglet "Informations Générales"
      }
    } catch (error) {
      console.error("Erreur lors de l'importation du nom de l'organisation:", error);
      setFormErrors({
        urlGrafana: "Une erreur s'est produite lors de l'importation. Veuillez vérifier l'orgId ou l'URL."
      });
      setFormSuccess({});
      setOrgImportSuccess(false);
    }
  };

  const handleValidateUrl = async () => {
    const { urlGrafana, orgIdGrafana, grafanaUsername, grafanaPassword } = project;
  
    // Vérifiez si l'URL se termine par un slash
    if (urlGrafana.endsWith('/')) {
      setFormErrors({
        urlGrafana: "L'URL Grafana ne doit pas se terminer par un slash (/)."
      });
      setFormSuccess({});
      setIsUrlVerified(false);
      return; // Arrête la validation si le format de l'URL est incorrect
    }
  
    try {
      const urlObj = new URL(urlGrafana);
      const baseGrafanaUrl = urlObj.origin + urlObj.pathname;
  
      const response = await axios.get(`${apiBaseUrl}/proxy/grafana/orgs/${orgIdGrafana}`, {
        params: { baseGrafanaUrl, grafanaUsername, grafanaPassword }
      });
  
      if (response.data.error === "Unauthorized") {
        setFormErrors({
          grafanaUsername: "Identifiant ou mot de passe incorrect.",
          grafanaPassword: "Identifiant ou mot de passe incorrect."
        });
        setFormSuccess({});
        setIsUrlVerified(false);
      } else {
        setFormSuccess({ urlGrafana: "L'URL Grafana est correctement formatée et accessible." });
        setFormErrors({});
        setIsUrlVerified(true);
      }
    } catch (error) {
      console.error("Erreur lors de la validation de l'URL Grafana:", error);
      setFormErrors({
        urlGrafana: "Erreur lors du contact avec Grafana. Veuillez vérifier l'URL et l'ID d'organisation."
      });
      setFormSuccess({});
      setIsUrlVerified(false);
    }
  };
  
  

  const isValidateUrlDisabled = !project.urlGrafana || !project.grafanaUsername || !project.grafanaPassword || !project.orgIdGrafana;
 

  if (loading) {
    return <div>Chargement...</div>;
  }

  return (
    <div className="flex flex-row min-h-screen">
      <div className="flex-grow" style={{ backgroundColor: '#F5F5F5' }}>
        <div className="w-full pt-6" style={{ height: '25vh', backgroundColor: '#166534' }}>
          <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 mt-20">
            <div className="bg-white p-4 shadow rounded-lg">
              <div className="flex items-center">
                <i
                  className="fas fa-arrow-left cursor-pointer"
                  onClick={handleArrowBackClick}
                  style={{ fontSize: '1.5rem', lineHeight: '3.5rem', color: '#000000', marginRight: '1rem' }}
                ></i>
                <h1 className="text-2xl font-bold">Configuration - {project.NomDuProjet}</h1>
              </div>

              <TabsGrafanaConfig 
                selectedTab={selectedTab} 
                handleTabChange={handleTabChange} 
                showGeneralTabAlert={showGeneralTabAlert}
              />
              <div className="max-w-5xl mx-auto bg-white p-6 border rounded-lg">
                {selectedTab === 'general' && (
                  <EditProject 
                    project={project} 
                    handleChange={handleChange} 
                    formErrors={formErrors} 
                    formSuccess={formSuccess} 
                  />
                )}
                {selectedTab === 'grafana' && (
                  <EditGrafanaConfig 
                  project={project} 
                  handleChange={handleChange} 
                  formErrors={formErrors} 
                  formSuccess={formSuccess}
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                  handleImportOrgName={handleImportOrgName}
                  handleValidateUrl={handleValidateUrl}
                  isUrlVerified={isUrlVerified}
                  isValidateUrlDisabled={isValidateUrlDisabled}
                />
                )}
                <button
                  onClick={() => saveProjectData(project)}
                  className={`bouton_vert mt-4 ${isSaveDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={isSaveDisabled}
                  title={isSaveDisabled ? "Veuillez remplir tous les champs Grafana ou les laisser tous vides" : ""}
                >
                  Enregistrer
                </button>
                {formSuccess.general && (
                  <p className="form-success text-green-600 mt-2">{formSuccess.general}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectConfig;
