import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useCampaigns } from '../CampaignContext';
import '../css/bouton.css';
import '../css/formulaire.css';
import '../css/tableau.css';
import GroupedDashboardImport from './GroupedDashboardImport';
import config from '../config/config';

const AddProjectDetail = ({ onAddSuccess, onClose }) => {
  const [detailData, setDetailData] = useState({
    DateDeDebut: '',
    DateDeFin: '',
    TitreCampagne: '',
    Description: '',
  });
  const [dashboards, setDashboards] = useState([{ url_Dashboard: '', nom: '', TypeDashboard: '' }]);
  const [message, setMessage] = useState('');
  const { id: projectId } = useParams(); // ID du projet
  const { addCampaign } = useCampaigns();
  const apiBaseUrl = config.FRONTEND.API_URL; 

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    setDetailData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
  
      // Si on modifie DateDeFin après DateDeDebut
      if (name === "DateDeFin" && updatedData.DateDeDebut && value < updatedData.DateDeDebut) {
        setMessage("La date de fin ne peut pas être antérieure à la date de début.");
        return prevData;
      }
  
      // Si on modifie DateDeDebut après avoir déjà rempli DateDeFin
      if (name === "DateDeDebut" && updatedData.DateDeFin && value > updatedData.DateDeFin) {
        setMessage("La date de début ne peut pas être postérieure à la date de fin.");
        return prevData;
      }
  
      setMessage(""); // Efface le message d'erreur s'il y a aucune erreur
      return updatedData;
    });
  };

  // Utilisez useEffect pour surveiller les mises à jour des dashboards
  useEffect(() => {
    setTimeout(() => {}, 100);
  }, [dashboards]);

  const prepareDashboardData = (dashboards) => {
    // Transformation des propriétés et filtrage des entrées vides
    const validDashboards = dashboards
      .map(dashboard => ({
        nom: dashboard.title, // Renomme 'title' en 'nom'
        url_Dashboard: dashboard.url, // Renomme 'url' en 'url_Dashboard'
        TypeDashboard: dashboard.TypeDashboard
      }))
      .filter(dashboard => dashboard.url_Dashboard && dashboard.nom && dashboard.TypeDashboard);
    return validDashboards;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Préparer les dashboards valides
      const validDashboards = prepareDashboardData(dashboards);
  
      if (validDashboards.length === 0) {
        console.warn("⚠️ Aucun dashboard importé, mais la campagne sera créée.");
      }
    
      const token = localStorage.getItem('token');
      const config = { headers: { Authorization: `Bearer ${token}` } };

  
      // Préparer les données pour la campagne
      const formattedDetailData = {
        ...detailData,
        DateDeDebut: detailData.DateDeDebut,
        DateDeFin: detailData.DateDeFin,
      };
  
      // Étape 1 : Créer la campagne
      const detailResponse = await axios.post(
        `${apiBaseUrl}/projets/${projectId}/details`,
        formattedDetailData,
        config
      );
      const newCampaign = detailResponse.data;
  
      if (!newCampaign || !newCampaign.id) {
        throw new Error("Erreur lors de la création de la campagne.");
      }
  
      // Étape 2 : Préparer les dashboards (même si c'est vide)
      const dashboardsPayload = {
        dashboards: validDashboards.length > 0 ? validDashboards.map((dashboard) => ({
            ...dashboard,
            ID_DetailProjet: newCampaign.id,
        })) : [],
      };

      // Étape 3 : Envoyer les dashboards seulement si la liste n'est pas vide
      if (dashboardsPayload.dashboards.length > 0) {
        await axios.post(
            `${apiBaseUrl}/projets/${projectId}/details/${newCampaign.id}/dashboards`,
            dashboardsPayload,
            config
        );
        console.log("✅ Dashboards ajoutés avec succès.");
      } else {
        console.log("⚠️ Aucun dashboard à ajouter, envoi de la requête ignoré.");
      }

      // Mises à jour de l'état après succès
      addCampaign(projectId, newCampaign);
      setMessage(validDashboards.length > 0 
        ? 'Campagne ajouté avec succès avec dashboards.' 
        : 'Campagne ajouté avec succès sans dashboards.');
    
      setDetailData({ DateDeDebut: '', TitreCampagne: '', Description: '' });
      setDashboards([{ url_Dashboard: '', nom: '', TypeDashboard: '' }]);
  
      if (onAddSuccess) {
        onAddSuccess();
      }
      if (onClose) {
        onClose();
      }
    } catch (error) {
      console.error(
        "Erreur lors de l'ajout d'une campagne:",
        error.response ? error.response.data : error
      );
      setMessage(
        error.response?.data?.errors
          ? error.response.data.errors[0].msg
          : "Erreur lors de l'ajout d'une campagne. Veuillez réessayer."
      );
    }
  };
  

  
  return (
    <div className="max-w-md mx-auto my-2 bg-white rounded max-w-2xl">
      {message && (
        <div className={message.includes('Erreur') ? "bg-red-500 text-white p-2 rounded" : "bg-red-100 border-l-4 border-red-500 text-red-800 p-2 my-2 rounded"}>
          {message}
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 form-label">Titre de la Campagne <span className="required">*</span></label>
          <input
            type="text"
            name="TitreCampagne"
            value={detailData.TitreCampagne}
            onChange={handleChange}
            placeholder="Entrez le titre de la campagne"
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm custom-focus"
          />
        </div>
        <div className="flex space-x-4">
          <div className="w-1/2">
          <label className="block text-sm font-medium text-gray-700 form-label">Date de Début <span className="required">*</span></label>
          <input
            type="datetime-local"
            name="DateDeDebut"
            value={detailData.DateDeDebut}
            onChange={handleChange}
            required
            max={detailData.DateDeFin || undefined} // Empêche de dépasser la date de fin
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm custom-focus"
          />
          </div>
        
          <div className="w-1/2">
          <label className="block text-sm font-medium text-gray-700 form-label">Date de Fin <span className="required">*</span></label>
          <input
            type="datetime-local"
            name="DateDeFin"
            value={detailData.DateDeFin}
            onChange={handleChange}
            required
            min={detailData.DateDeDebut || undefined} // Empêche d’aller avant la date de début
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm custom-focus"
          />
        </div>

        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Description</label>
          <textarea
            name="Description"
            value={detailData.Description}
            onChange={handleChange}
            placeholder="Ajoutez une description (facultatif)"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm custom-focus"
          ></textarea>
        </div>

        {/* Import groupé de dashboards */}
        <GroupedDashboardImport
          projectId={projectId}
          setDashboards={setDashboards}
          DateDeDebut={detailData.DateDeDebut} 
          DateDeFin={detailData.DateDeFin}
          TitreCampagne={detailData.TitreCampagne}
        />

        <div className="flex justify-between">
          <button type="submit" className="bouton_vert">Ajouter</button>
        </div>
      </form>
    </div>
  );
};

export default AddProjectDetail;
