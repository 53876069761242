// Modal.js
import React from 'react';

const Modal = ({ isOpen, onClose, children, title, sizeClass = "max-w-3xl" }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-40 flex items-center justify-center p-4">
      <div className={`bg-white rounded-lg shadow-xl relative w-full ${sizeClass} max-h-[80vh]`}>
        <button
          onClick={onClose}
          className="absolute top-1 right-3 text-3xl font-semibold text-gray-600 hover:text-gray-800"
        >
          &times;
        </button>
        <div className="p-8 border-b border-gray-300 flex items-center justify-between">
          <h2 className="text-lg font-semibold text-gray-800">{title}</h2>
          <p className="text-sm text-gray-500">
            <span className="text-red-500">*</span> : Champs obligatoires
          </p>
        </div>
        <div className="overflow-y-auto p-6" style={{ maxHeight: 'calc(80vh - 100px)' }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
