import React from 'react';

const EditGrafanaConfig = ({ 
  project, 
  handleChange, 
  formErrors, 
  formSuccess, 
  showPassword, 
  setShowPassword, 
  handleImportOrgName, 
  handleValidateUrl, 
  isUrlVerified,
  isValidateUrlDisabled 
}) => {
  return (
    <div>
      <div className="py-3 border-b border-gray-300 flex items-center justify-between mb-4">
          <h2 className="text-xl font-semibold mb-4">Configuration Grafana</h2>
          <p className="text-sm text-gray-500">
            <span className="text-red-500">*</span> : Champs obligatoires
          </p>
      </div>
      {formErrors.Grafana && (
        <p className="text-red-500 text-sm mb-4">{formErrors.Grafana}</p>
      )}
      <div className="mb-4">
        <label>URL Racine Grafana <span className="text-red-500">*</span></label>
        {formErrors.urlGrafana && (
          <p className="form-error text-red-500">{formErrors.urlGrafana}</p>
        )}
        {formSuccess.urlGrafana && (
          <p className="form-success text-green-600">{formSuccess.urlGrafana}</p>
        )}
        <input
          type="text"
          value={project.urlGrafana}
          onChange={(e) => handleChange('urlGrafana', e.target.value)}
          placeholder="https://demo.atakama-technologies.com/grafana/"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight custom-focus"
          autoComplete="off"
        />
      </div>
      <div className="mb-4">
        <label>ID Organisation (orgId) <span className="text-red-500">*</span></label>
        <input
          type="text"
          value={project.orgIdGrafana}
          onChange={(e) => handleChange('orgIdGrafana', e.target.value)}
          placeholder="Entrez l'OrgID manuellement"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight custom-focus"
          autoComplete="off"
        />
      </div>
      <div className="mb-4">
        <label>Nom d'utilisateur Grafana <span className="text-red-500">*</span></label>
        {formErrors.grafanaUsername && (
          <p className="text-red-500 text-sm">{formErrors.grafanaUsername}</p>
        )}
        <input
          type="text"
          value={project.grafanaUsername}
          onChange={(e) => handleChange('grafanaUsername', e.target.value)}
          placeholder="Entrez le nom d'utilisateur Grafana"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight custom-focus"
          autoComplete="new-username"
          name="grafanaUsername"
        />
      </div>
      <div className="mb-4">
        <label>Mot de passe Grafana <span className="text-red-500">*</span></label>
        {formErrors.grafanaPassword && (
          <p className="text-red-500 text-sm">{formErrors.grafanaPassword}</p>
        )}
        <div className="relative">
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder={showPassword ? "Entrez le mot de passe Grafana (visible)" : "Entrez le mot de passe Grafana (masqué)"}
            value={project.grafanaPassword}
            onChange={(e) => handleChange('grafanaPassword', e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight custom-focus"
            autoComplete="new-password"
            name="grafanaPassword"
          />
          <div
            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          >
            <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} text-gray-500`}></i>
          </div>
        </div>
      </div>

      {/* Boutons d'import et de validation */}
      <div className="mt-4">
      <button
          type="button"
          className={`bouton_vert_grafana ${isValidateUrlDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
          onClick={handleValidateUrl}
          disabled={isValidateUrlDisabled}
          title={isValidateUrlDisabled ? "Veuillez remplir tous les champs Grafana pour utiliser cette option" : ""}
        >
          Vérifier l'URL
        </button>
        
        <button
          type="button"
          className={`bouton_vert_grafana ml-2 ${(!isUrlVerified ? 'opacity-50 cursor-not-allowed' : '')}`}
          onClick={handleImportOrgName}
          disabled={!isUrlVerified}
          title={!isUrlVerified ? "Veuillez d'abord vérifier l'URL Grafana" : ""}
        >
          Importer le nom de l'organisation
        </button>
      </div>
    </div>
  );
};

export default EditGrafanaConfig;
